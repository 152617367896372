import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import Footer from '../components/Footer';
import NeoBrutalistButton from '../components/NeoBrutalistButton';
import { CheckIcon } from '@heroicons/react/solid';
import PlansPriceTable from '../components/PlansPriceTable';
import PageTitle from '../components/PageTitle';

const PricingPage = () => {
  const { t } = useTranslation();

  // const plans = [
  //   {
  //     name: 'Basic',
  //     price: 9,
  //     features: [
  //       t('Up to 70 exercises per month'),
  //       t('Use OpenAI API key for unlimited generations'),
  //       t('Access to all exercise types'),
  //       t('Email support'),
  //     ],
  //   },
  //   {
  //     name: 'Pro',
  //     price: 19,
  //     features: [
  //       t('Up to 180 exercises per month'),
  //       t('Use OpenAI API key for unlimited generations'),
  //       t('Access to all exercise types'),
  //       t('Priority email support'),
  //       t('Advanced analytics'),
  //       t('Custom branding'),
  //     ],
  //   },
  // ];

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      {/* <Header /> */}
      <main className="flex-grow container mx-auto px-4 pt-2 pb-10 pb-24">
      {/* <h1 className="text-6xl font-bold mb-8 text-center text-black transform -rotate-2 relative -top-[45px]">
          <span className="bg-red-500 text-white px-4 py-2 inline-block transform rotate-0">
            {t("Choose your plan")}
          </span>
        </h1> */}
        <PageTitle>
        {t("Choose your plan")}
        </PageTitle>
        <PlansPriceTable/>
      </main>
      <Footer />
    </div>
  );
};

export default PricingPage;