import React from 'react';
import { useDrop } from 'react-dnd';
import { TrashIcon } from '@heroicons/react/solid';

const DroppableFolder = ({ folder, onDrop, onDelete, children }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'EXERCISE',
   // drop: (item) => onDrop(item.id, folder._id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div ref={drop} style={{ backgroundColor: isOver ? 'lightgreen' : 'transparent' }} className="relative">
      {children}
      <button onClick={() => onDelete(folder._id)} className="absolute top-2 right-2 z-1">
        <TrashIcon className="h-6 w-6 text-red-500" />
      </button>
    </div>
  );
};

export default DroppableFolder;