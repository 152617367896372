import React, { useEffect, useRef, useState } from 'react';
import lottie from 'lottie-web';

const LottieAnimation = () => {
  const container = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false); // État pour gérer l'opacité

  const possibleAnimations = [
    'https://lottie.host/f375a885-c908-491e-84ed-91957f6cad43/KFEjP0zsGs.json',
    'https://lottie.host/b8d913d5-452c-4883-891f-15ebfbfd4e0f/qnfpJy7QeC.json',
    'https://lottie.host/37b17d2f-0b5f-4ee1-bab3-c22337308fe6/UdAPzfmoH6.json',
    'https://lottie.host/18934fbe-97c6-4ddd-85ed-a6e350ab73f1/GahtFPUk8E.json',
    'https://lottie.host/964c41fd-fe40-4806-8454-3e1a048c50c1/gJMwzh6kS4.json',
    
  ]



  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: possibleAnimations[Math.floor(Math.random() * possibleAnimations.length)]
    });

    // Une fois l'animation chargée, modifie les couleurs en manipulant les données JSON
    anim.addEventListener('DOMLoaded', () => {
      const elements = anim.renderer.elements;
      
      // Parcourir tous les éléments et modifier leur couleur (ex: pour changer la couleur d'un calque particulier)
      elements.forEach((element) => {
        // if (element.layer && element.layer.nm === 'NomDuCalqueQueTuVeuxModifier') {
        //   // Modifie la couleur de remplissage par exemple
        //   element.pathElement.setAttribute('fill', '#FF0000');
        // }
        setIsLoaded(true); 
      });
    });

    return () => anim.destroy(); // Nettoie à la destruction du composant
  }, []);

  return <div ref={container}  style={{
    opacity: isLoaded ? 1 : 0,
    transition: 'opacity 1s ease-in-out', // Transition pour le fade-in
  }} />;
};

export default LottieAnimation;
