import React from 'react';
import { useNavigate } from 'react-router-dom';

const Logo = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <div className="relative flex items-center hover:opacity-80 transition-all z-2" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
      <img src="/img/email_logo.png" alt="logo" className=" mr-4 md:max-h-[50px] max-h-[30px] relative z-2" style={{ zIndex: 2 }}  />
      <span className="hidden sm:block absolute top-0 right-4 bg-blue-500 text-white text-xs font-bold py-1 px-2 rounded-lg transform rotate-12 translate-x-1/2 -translate-y-1/2 z-0 ">
        BETA
      </span>
    </div>
  );
};

export default Logo;