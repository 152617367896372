import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaBell } from 'react-icons/fa';
import { axiosInstance } from '../axiosConfig';
import { FaCheckCircle, FaExclamationCircle, FaGift, FaExclamationTriangle, FaInfoCircle } from 'react-icons/fa';

const NotificationsDropdown = () => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showBadge, setShowBadge] = useState(true);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axiosInstance.get('/notifications', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        const newNotifications = response.data.map((notification) => {
          notification.message = t(notification.message);
          if (notification.message.includes("{{title}}")) {
            notification.message = notification.message.replace(
              "{{title}}",
              notification.type === "lesson" ? notification.lesson.title : notification.exercise.title
            );
          } else {
            notification.message = notification.message.replace(
              "{{title}}",
              ""
            );
          }
          if (notification.message.includes("{{subject}}")) {
            notification.message = notification.message.replace(
              "{{subject}}",
              t(notification.exercise.subject)
            );
          } else {
            notification.message = notification.message.replace(
              "{{subject}}",
              ""
            );
          }
  
          if (notification.message.includes("{{name}}")) {
            if (notification.student) {
              notification.message = notification.message.replace(
                "{{name}}",
                notification.student.name
              );
            } else {
              notification.message = notification.message.replace("{{name}}", "");
            }
          }

          if (notification.message.length > 100) {
            notification.message = notification.message.substring(0, 120) + "...";
          }

          return notification;
        });

        const limitedNotifications = newNotifications.slice(-3);
        setNotifications(limitedNotifications);
  
     //   setNotifications(newNotifications);

        //setNotifications(response.data);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, []);

  useEffect(() => {
    if(isOpen) {
      setShowBadge(false);
      axiosInstance.put('/notifications/mark-all-read', {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
    }
  }, [isOpen]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const getIconByType = (type) => {
    switch (type) {
      case "exercise":
        return <FaCheckCircle className="text-green-500" />;
      case "error":
        return <FaExclamationCircle className="text-red-500" />;
      case "gift":
        return <FaGift className="text-yellow-500" />;
      case "warning":
        return <FaExclamationTriangle className="text-orange-500" />;
      default:
        return <FaInfoCircle className="text-blue-500" />;
    }
  };

  return (
    <div className="relative z-40">
      <button
        onClick={toggleDropdown}
        className="hidden md:block flex items-center space-x-2 bg-white_ border-0 border-gray-300 px-0 py-2 relative -top-1 rounded-md shadow-sm_ hover:bg-gray-50 transition-colors duration-200"
      >
        <FaBell className="text-xl text-gray-500 hover:scale-110 transition-all duration-300 relative z-2" />
        {notifications.length > 0 && showBadge && (
          <span className="ml-1 text-sm font-bold absolute -right-3 -top-0 rounded-full bg-yellow-400 w-5 h-5 pt-[0px] -z-1">{notifications.length}</span>
        )}
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-64 bg-white border border-gray-200 rounded-md shadow-lg z-0 min-w-[320px]">
          <ul className="py-1">
            {notifications.length === 0 ? (
              <li className="px-4 py-2 text-gray-700">{t('No new notifications')}</li>
            ) : (
              notifications.map((notification, index) => (
                <li key={index} className="px-4 py-2 hover:bg-gray-100 transition-colors text-sm text-gray-700">
                  <div className="flex items-center space-x-2">
                    <div className="w-6 h-6">
                      {getIconByType(notification.type)}
                    </div>
                    <span>{t(notification.message)}</span>

                  </div>
                </li>
              ))
            )}
          </ul>
        </div>
      )}
    </div>
  );
  
};

export default NotificationsDropdown;