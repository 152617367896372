import React, { useState, useEffect } from "react";
// import { GoogleLogin } from "react-google-login";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

import { Link, useLocation } from "react-router-dom";
import { LockClosedIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import NeoBrutalistInput from "../components/NeoBrutalistInput";
import NeoBrutalistButton from "../components/NeoBrutalistButton";
import { useParams } from "react-router-dom";
import NeoBrutalistNotification from "../components/NeoBrutalistNotification";
import { useAuth } from "../contexts/AuthProvider";
import logger from "../utils/logger";
import { FaEnvelopeOpen, FaGoogle } from 'react-icons/fa';
import Header from "../components/Header";
import Footer from "../components/Footer";
import PasswordRecoveryModal from "../components/PasswordRecoveryModal";

const LoginPage = ({ isAuthenticated }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [notification, setNotification] = useState("");
  const [error, setError] = useState("");
  const { registrationToken } = useParams();
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [showEmailLogin, setShowEmailLogin] = useState(false);
  const [showGoogleLogin, setShowGoogleLogin] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const verifyEmailToken = async (registrationToken) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/verify-registration-token`,
        { registrationToken: registrationToken }
      );
      setNotificationMessage("Registration successful, you can now log in");
      setShowNotification(true);
    } catch (error) {
      setNotificationMessage("Registration failed");
      setShowNotification(true);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
  //  setShowGoogleLogin(searchParams.has("TEST"));
  }, [location]);

  useEffect(() => {
    if (registrationToken) {
      verifyEmailToken(registrationToken);
    }
  }, [registrationToken]);

  useEffect(() => {
    if (location.state && location.state.notification) {
      setNotification(location.state.notification);
    }
  }, [location.state]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        {
          email,
          password,
        }
      );
      logger.log("Login response:", response.data);
      localStorage.setItem("token", response.data.token);
      login(response.data.token);
      const redirectTo = location.state?.from || "/dashboard";
      document.location.href = redirectTo;
    //navigate(redirectTo);
    } catch (error) {
      logger.error("Login error:", error);
      setError("Invalid email or password");
    }
  };
  useEffect(() => {
    const loginPanel = document.querySelector('.max-w-md');
    if (loginPanel) {
      setTimeout(() => {
        loginPanel.scrollIntoView({ behavior: 'smooth' });
      }, 400); // Delay to ensure the element is fully rendered
    }
  }, []);

  useEffect(() => {
    return () => {
      if (location.state && location.state.notification) {
        navigate(location.pathname, { replace: true, state: {} });
      }
    };
  }, [location, navigate]);

  const handleGoogleSuccess = async (response) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/google/token`,
        {
          tokenId: response.credential,
        }
      );
      localStorage.setItem("token", res.data.token);
      login(res.data.token);
      document.location.href = "/dashboard";
     // navigate("/generator");
    } catch (error) {
      setError("Google login failed");
    }
  };

  ;

  const handleGoogleFailure = (error) => {
    console.log(error);
    setError("Google login failed");
  };

  return (
    <>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>

    <div className="min-h-screen_ bg-gray-100 flex flex-col ">
    {/* <Header /> */}
    <div className="flex-1 bg-gray-100 flex items-center justify-center py-16 px-4 sm:px-6 lg:px-8">
     
      <div id="loginPanel" className="max-w-md w-full space-y-8 bg-white p-6 rounded-lg shadow-md border-0 border-black ">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {t("Log in")}
          </h2>
        </div>
        <div className="mt-8 space-y-6">
          <NeoBrutalistButton
            style="secondary"
            className="w-full"
            onClick={() => {
              setShowEmailLogin(true);
              setError("");
            }}
          >
            <FaEnvelopeOpen className="h-5 w-5 mr-2" />
            {t("Login with Email")}
          </NeoBrutalistButton>
          <NeoBrutalistButton
            onClick={() => {
              const googleButton = document.querySelector('.btnGooleLogin #button-label');
              if (googleButton) googleButton.click();
            }}
            style="secondary"
            className="w-full flex items-center justify-center"
          >
            <FaGoogle className="h-5 w-5 mr-2" />
            {t("Login with Google")}
          </NeoBrutalistButton> 
 {showGoogleLogin && (
  <div className="btnGooleLogin hidden">
           <GoogleLogin
           onSuccess={handleGoogleSuccess}
           onError={handleGoogleFailure}
          //  render={(renderProps) => (
          //    <NeoBrutalistButton
          //      onClick={renderProps.onClick}
          //      disabled={renderProps.disabled}
          //      style="secondary"
          //      className="w-full flex items-center justify-center"
          //    >
          //      <FaGoogle className="h-5 w-5 mr-2" />
          //      {t("Login with Google")}
          //    </NeoBrutalistButton>
          //  )}
         />
         </div>
          )}
        </div>
        {showEmailLogin && (
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <NeoBrutalistInput
                label={t("Email")}
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <NeoBrutalistInput
                label={t("Password")}
                name="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
           
         
            <div>
              <NeoBrutalistButton
                type="submit"
                style="primary"
                className="w-full"
              >
                <LockClosedIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                {t("Enter")}
              </NeoBrutalistButton>
            </div>
          </form>
        )}
        {notification && (
          <div className="text-green-600 bg-gray-200 border-2 border-black p-4 rounded-lg shadow-2xl font-bold text-sm text-center">
            {notification}
          </div>
        )}
           {error && (
              <div className="text-red-500 bg-red-100 border-2 border-red-500 p-4 rounded-lg shadow-2xl font-bold">
                {t(error)}
              </div>
            )}
        <div className="flex justify-center gap-4 text-sm">
          <Link
            to="/register"
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            {t("Don't have an account yet?")}
          </Link>
  <button
                  onClick={() => setIsModalOpen(true)}
    className="font-medium text-indigo-600 hover:text-indigo-500"
  >
    {t("Forgot your password?")}
  </button>
</div>
      </div>
      {showNotification && (
        <NeoBrutalistNotification
          message={notificationMessage}
          onClose={() => setShowNotification(false)}
        />
      )}
    </div>
    <Footer />
    </div>
    </GoogleOAuthProvider>
    <PasswordRecoveryModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />

    </>
  );
};

export default LoginPage;
