import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const SubscriptionSuccessPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState(t('Verifying payment...'));

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');

    if (sessionId) {
      verifyPayment(sessionId);
    } else {
      setMessage(t('Error: Session ID not found'));
    }
  }, [location]);

  const verifyPayment = async (sessionId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/subscriptions/verify-payment`,
        { sessionId },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );

      if (response.data.success) {
        setMessage(t('Subscription updated successfully! Redirecting to the dashboard...'));
        setTimeout(() => navigate('/dashboard'), 3000);
      } else {
        setMessage(t('Error updating subscription'));
      }
    } catch (error) {
      console.error('Error verifying payment:', error);
      setMessage(t('Error verifying payment'));
    }
  };

  return (
    <div className="subscription-success">
      <h1>{t('Subscription Status')}</h1>
      <p>{message}</p>
    </div>
  );
};

export default SubscriptionSuccessPage;