import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import NeoBrutalistModal from "./NeoBrutalistModal";
import NeoBrutalistTextArea from "./NeoBrutalistTextArea";
import NeoBrutalistButton from "./NeoBrutalistButton";
import { PaperAirplaneIcon } from "@heroicons/react/solid";
import logger from "../utils/logger";

const FeedbackModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [feedback, setFeedback] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");
    setSuccessMessage("");

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/feedback`,
        { message: feedback },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setSuccessMessage(t("Feedback sent successfully"));
      setFeedback("");
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      logger.error("Erreur lors de l'envoi du feedback:", error);
      setError(t("Failed to send feedback. Please try again."));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <NeoBrutalistModal
      isOpen={isOpen}
      onClose={onClose}
      title={t("Send Feedback")}
    >
      <form onSubmit={handleSubmit} className="space-y-4">
        <NeoBrutalistTextArea
          label={t("Your Feedback")}
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          placeholder={t("Enter your feedback here...")}
          required
        />
        {error && (
          <p className="text-red-500 font-bold">{error}</p>
        )}
        {successMessage && (
          <p className="text-green-500 font-bold">{successMessage}</p>
        )}
        <div className="flex justify-end">
          <NeoBrutalistButton
            type="submit"
            disabled={isSubmitting}
            style="primary"
          >
            <PaperAirplaneIcon className="h-5 w-5 mr-2" />
            {isSubmitting ? t("Sending...") : t("Send Feedback")}
          </NeoBrutalistButton>
        </div>
      </form>
    </NeoBrutalistModal>
  );
};

export default FeedbackModal;