import React from 'react';
import { Link } from 'react-router-dom';

const HeaderLink = ({ to, children, active, onClick=null }) => {
  return (
    <Link 
      to={to}
      className={`relative block w-full px-4 py-2 text-black font-extrabold uppercase tracking-wider md:border-b-4 border-black transition-all duration-150 ease-in-out after:content-[''] after:absolute after:bottom-0 after:left-0 md:after:w-full after:h-1 ${active ? 'after:bg-blue-500 after:translate-y-1 after:h-full text-white ' : 'after:bg-yellow-400 after:translate-y-1'} hover:after:translate-y-0 hover:after:h-full after:transition-all after:duration-150 after:ease-in-out hover:text-white`}
      onClick={onClick}
    >
      <span className="relative z-10 flex items-center">{children}</span>
    </Link>
  );
};

export default HeaderLink;