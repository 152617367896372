import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import NeoBrutalistButton from "./NeoBrutalistButton";
import NeoBrutalistInput from "./NeoBrutalistInput";
import NeoBrutalistModal from "./NeoBrutalistModal";

const PasswordRecoveryModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [hideBtn, setHideBtn] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");
    if(email === ""){
      setError(t("Email is required"));
      return;
    }

    setHideBtn(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/forgot-password`,
        { email }
      );
      setMessage(t('Password reset email sent'));
    } catch (error) {
      setError(t("Failed to send password reset email"));
    }
  };

  return (
    <NeoBrutalistModal isOpen={isOpen} onClose={onClose} title={t("Forgot Password")} className="max-w-md">
      <form onSubmit={handleSubmit}>
        <NeoBrutalistInput
          label={t("Email")}
          name="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {!hideBtn && (
        <NeoBrutalistButton type="submit" style="primary" className="w-full mt-4">
          {t("Send Reset Link")}
        </NeoBrutalistButton>
        )}
      </form>
      <div className="text-center">
      {message && <div className="text-green-600 mt-4 text-sm">{message}</div>}
      {error && <div className="text-red-600 mt-4 text-sm">{error}</div>}
      </div>
    </NeoBrutalistModal>
  );
};

export default PasswordRecoveryModal;