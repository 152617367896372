import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NeoBrutalistCard from "../components/NeoBrutalistCard";
import NeoBrutalistButton from "../components/NeoBrutalistButton";
import NeoBrutalistModal from "../components/NeoBrutalistModal";
import Footer from "../components/Footer";
import logger from "../utils/logger";

const StudentCorrectionPage = () => {
  const { t } = useTranslation();
  const { uniqueLink } = useParams();
  const [correction, setCorrection] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);
  const [studentName, setStudentName] = useState("");


  useEffect(() => {
    fetchCorrection();
  }, [uniqueLink]);

  const fetchCorrection = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/exercise-reply/correction/${uniqueLink}`
      );
      setCorrection(response.data);
      setStudentName(response.data.student.firstName); // Assumes the API returns student info

  //    logger.log(response.data);
    } catch (error) {
      logger.error("Erreur lors de la récupération de la correction:", error);
      setError(t("Failed to load correction. Please try again later."));
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-yellow-100">
        <div className="text-4xl font-bold text-black border-8 border-black p-8 rounded-lg shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] bg-white">
          {t("Loading correction...")}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen bg-red-100">
        <div className="text-2xl font-bold text-black border-8 border-black p-8 rounded-lg shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] bg-white">
          {error}
        </div>
      </div>
    );
  }

  const handleQuestionClick = (index) => {
    setSelectedQuestionIndex(index);
  };

  const closeModal = () => {
    setSelectedQuestionIndex(null);
  };

  const renderMatching = (question, answer) => {
    console.log(answer);
    if(answer){
    const answerArray = answer.split(',').map(Number);
    return (
      <div className="space-y-6">
        {question.matchingSets.map((set, setIndex) => (
          <div key={setIndex} className="border-4 border-black p-4 bg-yellow-100 rounded-lg shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
            <h3 className="text-xl font-bold mb-4">
              {set.leftColumnTitle} - {set.rightColumnTitle}
            </h3>
            <div className="space-y-2">
              {set.leftColumn.map((leftItem, itemIndex) => (
                <div key={itemIndex} className="flex items-center space-x-4">
                  <div className="w-1/2 bg-yellow-200 border-2 border-black p-2 rounded">
                    {leftItem}
                  </div>
                  <div className="w-1/2 bg-blue-200 border-2 border-black p-2 rounded">
                    {set.rightColumn[answerArray[itemIndex]]}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return <div>No answer</div>;
  }
  };
  
  const renderFillInTheBlanks = (question, answer) => {
    const parts = question.text.split(/(__\d+__)/);
    return (
      <div className="space-y-4">
        {parts.map((part, index) => {
          const match = part.match(/__(\d+)__/);
          if (match) {
            const holeIndex = parseInt(match[1]) - 1;
            return (
              <span key={index} className="font-bold underline">
                {answer[holeIndex] || '____'}
              </span>
            );
          }
          return <span key={index}>{part}</span>;
        })}
      </div>
    );
  };
  
  return (
    <>
      <div className="min-h-screen bg-yellow-100 p-8 font-sans">
        <div className="max-w-4xl mx-auto">
          <NeoBrutalistCard className="mb-8 bg-white border-8 border-black shadow-[12px_12px_0px_0px_rgba(0,0,0,1)]" interactable={false}>
            <h1 className="text-4xl font-extrabold mb-4 text-center uppercase tracking-wider">
              {t('Here is your correction')+', '+studentName+'!'}
            </h1>
            <h2 className="text-3xl font-bold mb-2 text-center">
              {correction.exercise.title}
            </h2>
            <hr className="border-2 border-black max-w-lg mx-auto mt-6 mb-8" />
            <p className="text-xl mb-6 text-center italic">
              {correction.exercise.text}
            </p>
  
            <div className="flex justify-between items-center -mb-16">
              <div className="flex-1"></div>
              <div
                className={`text-5xl font-black transform -rotate-3 border-4 border-black p-4 shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] ${
                  correction.overallScore <= correction.maxScore / 3
                    ? "bg-red-300"
                    : correction.overallScore <= (correction.maxScore * 2) / 3
                    ? "bg-yellow-300"
                    : "bg-green-300"
                }`}
              >
                {correction.overallScore + " / " + correction.maxScore}
              </div>
            </div>
  
            {correction.overallFeedback && (
              <div className="mt-6 p-4 bg-green-100 border-4 border-black">
                <h3 className="text-2xl font-bold mb-2">
                  {t("Feedback from")+' '+correction.teacher.settings.displayedName}
                </h3>
                <p className="text-lg italic" dangerouslySetInnerHTML={{ __html: correction.overallFeedback.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>').replace(/\n/g, '<br />') }}></p>
              </div>
            )}
          </NeoBrutalistCard>
  
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {correction.answers.map((answer, index) => (
              <NeoBrutalistButton
                key={index}
                onClick={() => handleQuestionClick(index)}
                className="h-full flex flex-col justify-between p-4 bg-white border-4 border-black transition-all hover:shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] hover:-translate-y-1"
              >
                <h3 className="text-2xl font-bold mb-2">
                  {t("Question")} {index + 1}
                </h3>
                <p className="mb-2 text-xl">
                  {t("Your Score")}:{" "}
                  <span className="font-bold">
                    {answer.correction.score}/{correction.maxScore}
                  </span>
                </p>
                <p className="text-lg font-semibold text-blue-600">
                  {t("Click to view details")}
                </p>
              </NeoBrutalistButton>
            ))}
          </div>
  
          <NeoBrutalistModal
            isOpen={selectedQuestionIndex !== null}
            onClose={closeModal}
            title={`${t("Question")} ${
              selectedQuestionIndex !== null ? selectedQuestionIndex + 1 : ""
            }`}
          >
            {selectedQuestionIndex !== null && (
              <div className="space-y-6">
                <div className="border-4 border-black p-4 bg-yellow-100 rounded-lg shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
                  <h3 className="text-2xl font-bold mb-2">{t("Question")}</h3>
                  <p className="text-lg">
                    {
                      correction.exercise.questions[selectedQuestionIndex]
                        .question
                    }
                  </p>
                </div>
  
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="border-4 border-black p-4 bg-blue-100 rounded-lg shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
                    <h3 className="text-xl font-bold mb-2">{t("Your Answer")}</h3>
                    <p className="text-lg">
                      {correction.exercise.type === "multiple_choice"
                        ? correction.exercise.questions[selectedQuestionIndex]
                            .choices[
                            correction.answers[selectedQuestionIndex].answer
                          ]
                        : correction.exercise.type === "fill_in_the_blanks"
                        ? renderFillInTheBlanks(
                            correction.exercise.questions[selectedQuestionIndex],
                            correction.answers[selectedQuestionIndex].answer
                          )
                        : correction.exercise.type === "matching"
                        ? renderMatching(
                            correction.exercise.questions[selectedQuestionIndex],
                            correction.answers[selectedQuestionIndex].answer
                          )
                        : correction.answers[selectedQuestionIndex].answer}
                    </p>
                  </div>
  
                  <div className="border-4 border-black p-4 bg-green-100 rounded-lg shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
                    <h3 className="text-xl font-bold mb-2">
                      {t("Correct Answer")}
                    </h3>
                    <p className="text-lg">
                      {correction.exercise.type === "multiple_choice"
                        ? correction.exercise.questions[selectedQuestionIndex]
                            .choices[
                            correction.exercise.questions[selectedQuestionIndex]
                              .answer
                          ]
                        : correction.exercise.type === "fill_in_the_blanks"
                        ? renderFillInTheBlanks(
                            correction.exercise.questions[selectedQuestionIndex],
                            correction.exercise.questions[selectedQuestionIndex]
                              .answer
                          )
                        : correction.exercise.type === "matching"
                        ?  renderMatching(
                          correction.exercise.questions[selectedQuestionIndex],
                          correction.exercise.questions[selectedQuestionIndex].answer
                        )
                        : correction.exercise.questions[selectedQuestionIndex]
                            .answer}
                    </p>
                  </div>
                </div>
  
                <div className="flex justify-between items-center gap-4">
                  {correction.answers[selectedQuestionIndex].correction
                    .feedback ? (
                    <div className="flex-1 border-4 border-black p-4 bg-pink-100 rounded-lg shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] transform -rotate-1">
                      <h3 className="text-xl font-bold mb-2">{t("Feedback")}</h3>
                      <p className="text-lg italic">
                        {
                          correction.answers[selectedQuestionIndex].correction
                            .feedback
                        }
                      </p>
                    </div>
                  ) : (
                    <div className="flex-1"></div>
                  )}
                  <div className="border-4 border-black p-4 bg-purple-100 rounded-lg shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] transform rotate-1">
                    <h3 className="text-2xl font-bold mb-2">{t("Your Score")}</h3>
                    <p className="text-4xl font-black">
                      {correction.answers[selectedQuestionIndex].correction.score}
                      /{correction.maxScore}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </NeoBrutalistModal>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default StudentCorrectionPage;
