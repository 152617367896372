import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import NeoBrutalistInput from "../components/NeoBrutalistInput";
import NeoBrutalistSelect from "../components/NeoBrutalistSelect";
import NeoBrutalistButton from "../components/NeoBrutalistButton";
import { axiosInstance } from "../axiosConfig";
import NeoBrutalistTextArea from "../components/NeoBrutalistTextArea";
import PlansPriceTable from "../components/PlansPriceTable";
import Footer from "../components/Footer";
import PaymentModal from "../components/PaymentModal";
import { useLocation } from "react-router-dom";
import NeoBrutalistNotification from "../components/NeoBrutalistNotification";
import { useNavigate } from "react-router-dom";
import logger from "../utils/logger";
import PageTitle from "../components/PageTitle";

const SettingsPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [settings, setSettings] = useState({
    gradeTotal: 0,
    illustrationsStyles: "",
    displayedName: "",
    autoCorrectionsInstructions: "",
    language: "fr",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [planId, setPlanId] = useState("");
  const [notificationText, setNotificationText] = useState("");
  const [billingPortalUrl, setBillingPortalUrl] = useState("");


  useEffect(() => {
    fetchSettings();
   // fetchSubscription();
  }, []);
  useEffect(() => {
    if (planId == 'basic' || planId == 'pro') {
      fetchBillingPortalUrl();
    }
  }, [planId]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const newSubscription = searchParams.get("newSubscription");
    if (newSubscription) {
      setNotificationText("Subscription activated successfully!");

      const validateNewSubscription = async (sessionId) => {
        try {
          const response = await axiosInstance.post(
            `${process.env.REACT_APP_API_URL}/subscriptions/verify-payment`,
            { sessionId: newSubscription },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (response.data.success) {
            setNotificationText(
              `Subscription activated successfully! Plan: ${response.data.plan}`
            );
            setPlanId(response.data.plan);
            setTimeout(() => {
              navigate("/settings");
            }, 1000);
          } else {
            setNotificationText("Failed to activate subscription.");
          }
        } catch (error) {
          logger.error("Error validating subscription:", error);
          setNotificationText("Error validating subscription.");
        }
      };

      if (newSubscription) {
        validateNewSubscription(newSubscription);
      }
    }
  }, []);

  const fetchSubscription = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/subscriptions/getMyPlanId`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      logger.log(response.data);
      setPlanId(response.data.plan);
    } catch (error) {
      logger.error("Erreur lors de la récupération des paramètres:", error);
    //  setError(t("Error fetching settings"));
    }
  };

  // Appeler fetchSubscription toutes les 5 minutes
 /* useEffect(() => {
    fetchSubscription();
    const interval = setInterval(fetchSubscription, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);*/

  const fetchSettings = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/settings`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setSettings(response.data);
    } catch (error) {
      logger.error("Erreur lors de la récupération des paramètres:", error);
      setError(t("Error fetching settings"));
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");
    setSuccessMessage("");

    try {
      await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/settings`,
        settings,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setSuccessMessage(t("Settings updated successfully"));
    } catch (error) {
      logger.error("Erreur lors de la mise à jour des paramètres:", error);
      setError(t("Error updating settings"));
    } finally {
      setIsLoading(false);
    }
  };

  const handlePlanSelection = (planName) => {
    if (planName === "basic" || planName === "pro") {
      //  const selectedPlan = plans.find(plan => plan.name.toLowerCase() === planName);
      // onOpenPaymentModal(planName, selectedPlan.price);
    } else {
      // onSelectPlan(planName);
    }
    //fetchSettings();
    //fetchSubscription();
    logger.log("planName", planName);
    navigate("/settings");
  };

  // const handleSubscribe = async (paymentMethodId, plan) => {
  //   try {
  //     const response = await axiosInstance.post(
  //       `${process.env.REACT_APP_API_URL}/subscriptions/create-checkout-session`,
  //       { plan, paymentMethodId },
  //       {
  //         headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  //       }
  //     );

  //     // Rediriger vers la page de succès de Stripe
  //     window.location.href = response.data.sessionUrl;
  //   } catch (error) {
  //     logger.error("Erreur lors de la création de l'abonnement:", error);
  //     setError(t("Error creating subscription"));
  //   } finally {
  // //    setIsPaymentModalOpen(false);
  //   }
  // };

  const handleSubscriptionCanceled = () => {
    logger.log("Subscription canceled");
    fetchSettings();
    fetchSubscription();
    navigate("/settings");
  };

  const fetchBillingPortalUrl = async () => {
    
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/subscriptions/billing-portal`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setBillingPortalUrl(response.data.url);
    } catch (error) {
      logger.error("Erreur lors de la récupération de l'URL du portail de facturation:", error);
    }
  };
  const handleManageSubscription = () => {
    if (billingPortalUrl) {
      window.location.href = billingPortalUrl;
    }
  };

  return (
    <>
        {/* <Header activeLink="settings" /> */}
<div className="min-h-screen bg-gray-200 px-4 py-8">
        {/* <h1 className="text-3xl md:text-4xl font-bold mb-8 text-center text-black border-b-4 border-black pb-2">
        {t("Settings")}
        </h1> */}
       
        <PageTitle>{t("My Plan")}</PageTitle>
         <div className=" bg-gray-200 flex items-center justify-center py-0 px-4 sm:px-6 lg:px-8 pb-8 max-w-[1200px] w-full mx-auto">
          <PlansPriceTable
            onSelectPlan={(planName) => handlePlanSelection(planName)}
            currentPlanId={planId}
            onCanceled={handleSubscriptionCanceled}
          />
         
          
        </div>
        {(planId === "basic" || planId === "pro") && billingPortalUrl && (
          <div className="flex justify-center pb-12">
            <NeoBrutalistButton onClick={handleManageSubscription}>
              {t("Manage my subscription")}
            </NeoBrutalistButton>
          </div>
        )}
      </div>
      <Footer />

      {notificationText && (
        <NeoBrutalistNotification message={notificationText} type="success" />
      )}
    </>
  );
};

export default SettingsPage;
