import React, { useState } from 'react';
import NeoBrutalistModal from './NeoBrutalistModal';
import NeoBrutalistButton from './NeoBrutalistButton';
import { useTranslation } from 'react-i18next';
import { CreditCardIcon } from '@heroicons/react/solid';
import logger from '../utils/logger';
import { axiosInstance } from '../axiosConfig';
import { loadStripe } from '@stripe/stripe-js';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);


const PurchaseCreditsModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [creditAmount, setCreditAmount] = useState(200); // État pour le nombre de crédits
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const creditPrice = 0.09;

  const handlePurchase = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const stripe = await stripePromise;
      const response = await axiosInstance.post('/subscriptions/purchase-credits', {
        amount: creditAmount,
      });

      const { sessionId } = response.data;

      const result = await stripe.redirectToCheckout({
        sessionId: sessionId,
      });

      if (result.error) {
        setError(result.error.message);
      }
    } catch (error) {
      logger.error("Erreur lors de l'achat de crédits:", error);
      setError(t("An error occurred while processing your purchase. Please try again."));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <NeoBrutalistModal isOpen={isOpen} onClose={onClose} title={t('Purchase Credits')} className="max-w-xl">
      <div className="space-y-6">
        <p className="text-sm md:text-lg">{t('Select the amount of credits you want to purchase')}:</p>
        <div className="flex flex-col items-center space-y-4">
          <div className="w-full relative">
            <input
              type="range"
              min="100"
              max="2000"
              step="100"
              value={creditAmount}
              onChange={(e) => setCreditAmount(parseInt(e.target.value))}
              className="w-full  h-8 appearance-none bg-white border-4 border-black rounded-none cursor-pointer"
              style={{
                boxShadow: '4px 4px 0px 0px rgba(0,0,0,1)',
              }}
            />
             <div 
              className="absolute top-0 left-0 h-8 bg-yellow-400 border-r-4 border-black pointer-events-none" 
              style={{ width: `${((creditAmount - 100) / 1900) * 100}%` }} // Correction de la plage
            ></div>
          </div>
          <style jsx>{`
            input[type="range"] {
              z-index: 2;
              background: transparent;
            }
            input[type="range"]::-webkit-slider-thumb {
              -webkit-appearance: none;
              appearance: none;
              width: 40px;
              height: 40px;
              background: #fbbf24;
              border: 4px solid black;
              cursor: pointer;
              box-shadow: 4px 4px 0px 0px rgba(0,0,0,1);
              z-index: 3;
              position: relative;
            }
            input[type="range"]::-moz-range-thumb {
              width: 40px;
              height: 40px;
              background: #fbbf24;
              border: 4px solid black;
              cursor: pointer;
              box-shadow: 4px 4px 0px 0px rgba(0,0,0,1);
              z-index: 3;
              position: relative;
            }
          `}</style>
          <p className="text-xl md:text-2xl font-bold">
            {creditAmount} {t('Credits')} - {(creditAmount * creditPrice).toFixed(2)}€
          </p>
          <NeoBrutalistButton onClick={handlePurchase}>
            <CreditCardIcon className="w-4 h-4 mr-2" />
            {t('Purchase')}
          </NeoBrutalistButton>
        </div>
      </div>
    </NeoBrutalistModal>
  );
};

export default PurchaseCreditsModal;