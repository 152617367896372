import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import Footer from '../components/Footer';
import PageTitle from '../components/PageTitle';
import { useAuth } from '../contexts/AuthProvider';

const AboutUsPage = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
    {/* <Header activeLink="documentation" /> */}
    <main className={`container mx-auto px-4 pb-16 ${isAuthenticated ? 'pt-8' : 'pt-2'}`}>
    <PageTitle>
{t("About us")}
</PageTitle>
        <section className="bg-white p-8 rounded-lg shadow-md max-w-4xl mx-auto">
        <h2 className="text-2xl font-semibold mb-4 text-gray-800">
    {t('aboutUsPage.welcome')}
  </h2>
  <p className="text-lg mb-4">
    {t('aboutUsPage.intro')}
  </p>
  <p className="text-lg mb-4">
    {t('aboutUsPage.creator')}
  </p>
  <p className="text-lg mb-4">
    {t('aboutUsPage.innovation')}
  </p>
  <p className="text-lg mb-4">
    {t('aboutUsPage.vision')}
  </p>
  <p className="text-lg mb-4">
    {t('aboutUsPage.thankYou')}
  </p>
  <div className="text-center mt-8">
    <a href="https://loopus.tech" className="text-blue-500 hover:underline">
      {t('aboutUsPage.visitWebsite')}
    </a>
  </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default AboutUsPage;