import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NeoBrutalistModal from "./NeoBrutalistModal";
import NeoBrutalistButton from "./NeoBrutalistButton";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { axiosInstance } from "../axiosConfig";
import logger from "../utils/logger";

const PaymentModal = ({ isOpen, onClose, plan, onSubscribe }) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  useEffect(() => {
    logger.log("plan", plan);
  }, [plan]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setError(error.message);
      setProcessing(false);
    } else {
      onSubscribe(paymentMethod.id, plan);
    }
  };

  const nextBillingDate = new Date();
  nextBillingDate.setMonth(nextBillingDate.getMonth() + 1);

  return (
    <NeoBrutalistModal
      isOpen={isOpen}
      onClose={onClose}
      title={t("Subscribe to") + " " + plan}
    >
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="bg-yellow-300 border-4 border-black p-6 transform rotate-2 shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] max-w-[400px] mx-auto text-center">
          <p className="text-3xl font-bold mb-2">
            {t("Monthly cost")}: <span className="text-4xl">${plan ? plan.price : 0}</span>
          </p>
          <p className="text-xl">
            {t("Next billing date")}: <span className="font-bold">{nextBillingDate.toLocaleDateString()}</span>
          </p>
        </div>

        <div className="bg-blue-200 border-4 border-black p-6 transform -rotate-1 shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
          <h3 className="text-2xl font-bold mb-4">{t("Enter your card details")}</h3>
          <div className="bg-white border-4 border-black p-6 transform rotate-2 shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#7a8b94", // Darker placeholder color
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
          </div>
        </div>

        {error && <div className="text-red-500 bg-red-100 border-2 border-red-500 p-4 font-bold text-center max-w-[400px] mx-auto border-4 border-black shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">{error}</div>}
       
        
        <NeoBrutalistButton
          type="submit"
          disabled={!stripe || processing}
          style="primary"
          className="w-full text-xl py-3"
        >
          {processing ? t("Processing...") : t("Subscribe Now!")}
        </NeoBrutalistButton>
      </form>
    </NeoBrutalistModal>
  );
};

export default PaymentModal;