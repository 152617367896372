import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import Footer from '../components/Footer';
import {
  AcademicCapIcon,
  UserGroupIcon,
  PencilAltIcon,
  ClipboardCheckIcon,
  ChartBarIcon,
  CogIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/outline';
import { useAuth } from '../contexts/AuthProvider';
import PageTitle from '../components/PageTitle';

const DocumentationPage = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();

  const sections = [
    {
      title: t('Exercise Creation'),
      icon: <AcademicCapIcon className="w-8 h-8" />,
      content: [
        t('Access the "Generate an exercise" page from the main menu.'),
        t('Choose the subject, level, and type of exercise (MCQ, fill-in-the-blanks, etc.).'),
        t('Specify the instructions and the desired number of questions.'),
        t('Click on "Generate" to create your exercise with AI assistance.'),
        t('You can then modify the generated exercise if necessary.'),
      ],
    },
    {
      title: t('Student Management'),
      icon: <UserGroupIcon className="w-8 h-8" />,
      content: [
        t('In the "My Students" section, you can add, edit or delete students.'),
        t('To add a student, click on "Add a student" and fill in the required information.'),
        t('You can invite students by email to join your virtual classroom.'),
        t('View your students\' performance statistics and export their grades in CSV format.'),
      ],
    },
    {
      title: t('Sending Exercises'),
      icon: <PencilAltIcon className="w-8 h-8" />,
      content: [
        t('From the "My Exercises" page, select the exercise you want to send.'),
        t('Click on the send icon and choose the recipient students.'),
        t('Students will receive an email with a unique link to access the exercise.'),
        t('You will be notified when a student has completed the exercise.'),
      ],
    },
    {
      title: t('Correcting Exercises'),
      icon: <ClipboardCheckIcon className="w-8 h-8" />,
      content: [
        t('Access exercises to be corrected from your dashboard or the "My Exercises" page.'),
        t('Click on an exercise to see the student\'s answers.'),
        t('Use the correction interface to evaluate each answer and provide feedback.'),
        t('AI will assist you in the correction by suggesting comments and grades.'),
        t('Once the correction is complete, the student will be notified and can view their results.'),
      ],
    },
    {
      title: t('Performance Tracking'),
      icon: <ChartBarIcon className="w-8 h-8" />,
      content: [
        t('View overall statistics on your dashboard.'),
        t('In the "My Students" section, you\'ll find detailed graphs for each student.'),
        t('Analyze performance by subject and over time.'),
        t('Export performance data in CSV format for more in-depth analysis.'),
      ],
    },
    {
      title: t('Settings'),
      icon: <CogIcon className="w-8 h-8" />,
      content: [
        t('Access your account settings from the user menu.'),
        t('Customize your automatic correction and exercise generation preferences.'),
        t('Manage your subscription and billing information.'),
        t('Configure your email notification preferences.'),
      ],
    },
  ];

  const faqs = [
    {
      question: t('How to create an account?'),
      answer: t('Click on the "Sign up" button on the homepage and fill out the registration form with your information.'),
    },
    {
      question: t('Can I import my own exercises?'),
      answer: t('Yes, you can import your own exercises via the dashboard by clicking on "Import an exercise".'),
    },
    {
      question: t('How do my students access the exercises?'),
      answer: t('Your students will receive an email with a unique link to access the exercises you have sent them.'),
    },
    {
      question: t('Is the application compatible with mobile devices?'),
      answer: t('Yes, our application is optimized for use on computers, tablets, and smartphones.'),
    },
    {
      question: t('How can I contact customer support?'),
      answer: t('You can contact us via the contact form available in the footer or by sending an email to support@loopus.tech.'),
    },
    {
      question: t('Can AI automatically correct exercises?'),
      answer: t('Yes, AI can automatically correct student responses according to the criteria you have defined.'),
    },
    {
      question: t('How can I customize automatic correction instructions?'),
      answer: t('In your account settings, you can specify your preferences for automatic exercise correction.'),
    },
    {
      question: t('Is there a limit to the number of exercises I can create?'),
      answer: t('You can create exercises as long as you have available credits. You can recharge your credits at any time or obtain them monthly through your subscription. Check our pricing page for more details on recharge and subscription options.'),
    },
    {
      question: t('Is my data secure?'),
      answer: t('Yes, we use the latest security standards to protect your data and that of your students.'),
    },
    {
      question: t('Can I export exercises in PDF format?'),
      answer: t('Yes, you can export exercises and correction instructions in PDF format for easy distribution or printing.'),
    },
  ];

  const [activeFaq, setActiveFaq] = React.useState(null);

const toggleFaq = (index) => {
  setActiveFaq(activeFaq === index ? null : index);
};

return (
  <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
    {/* <Header activeLink="documentation" /> */}
    <main className={`container mx-auto px-4 pb-16 ${isAuthenticated ? 'pt-8' : 'pt-2'}`}>
     <PageTitle>
      {t("Documentation")}
     </PageTitle>
      <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 pb-12 ${isAuthenticated ? 'pt-8' : ''}`}>
        {sections.map((section, index) => (
          <div key={index} className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
            <h2 className="text-2xl font-semibold mb-4 flex items-center text-gray-800">
              <div className="bg-blue-100 p-3 rounded-full mr-3">
                {React.cloneElement(section.icon, { className: "w-6 h-6 text-blue-600" })}
              </div>
              <span>{section.title}</span>
            </h2>
            <ul className="space-y-2 text-gray-600">
              {section.content.map((item, itemIndex) => (
                <li key={itemIndex} className="flex items-start mb-2 text-sm">
                  <span className="text-blue-500 mr-2">•</span>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="bg-white p-8 rounded-xl shadow-lg mt-16 mb-8">
        <h2 className="text-3xl font-bold mb-8 text-gray-800 flex items-center">
          <QuestionMarkCircleIcon className="w-8 h-8 mr-3 text-blue-600" />
          <span>{t('Foire aux questions (FAQ)')}</span>
        </h2>
        {faqs.map((faq, index) => (
          <div key={index} className="mb-4 border-b border-gray-200 last:border-b-0">
            <h3 
              className={`text-xl font-semibold p-4 cursor-pointer flex justify-between items-center text-gray-700 hover:text-blue-600 transition-colors duration-200`} 
              onClick={() => toggleFaq(index)}
            >
              {faq.question}
              <span className="text-2xl">{activeFaq === index ? '−' : '+'}</span>
            </h3>
            <div className={`transition-all duration-300 ${
              activeFaq === index ? 'max-h-screen py-4' : 'max-h-0 overflow-hidden'
            }`}>
              <p className="text-gray-600 pl-4">{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </main>
    <Footer />
  </div>
);

};

export default DocumentationPage;