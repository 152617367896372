// client/src/components/Notification.js
import React from 'react';
import { useNotification } from '../contexts/NotificationContext';

const Notification = () => {
  const { notification } = useNotification();

  if (!notification) return null;

  return (
    <div className={`fixed bottom-4 right-4 bg-yellow-400 text-black font-bold px-4 py-2 pr-6 rounded-lg border-4 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] z-50 max-w-[320px] opacity-100 pointer-events-auto`}>

      {notification}
    </div>
  );
};

export default Notification;