import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import Footer from '../components/Footer';

const TermsPage = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* <Header /> */}
      <main className="container mx-auto px-4 py-8 max-w-4xl">
        <h1 className="text-3xl font-bold mb-6">{t('Terms and Conditions')}</h1>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">{t('1. Acceptance of Terms')}</h2>
          <p className="mb-4">
            {t('By accessing and using this website, you accept and agree to be bound by the terms and provision of this agreement. In addition, when using this website\'s particular services, you shall be subject to any posted guidelines or rules applicable to such services.')}
          </p>

          <h2 className="text-2xl font-semibold mb-4">{t('2. Description of Service')}</h2>
          <p className="mb-4">
            {t('This website provides a platform for generating and managing educational exercises using artificial intelligence. The service is provided "as is" and we reserve the right to modify or discontinue the service at any time without prior notice.')}
          </p>

          <h2 className="text-2xl font-semibold mb-4">{t('3. User Obligations')}</h2>
          <p className="mb-4">
            {t('You agree to use the service for lawful purposes only and in a way that does not infringe the rights of, restrict or inhibit anyone else\'s use and enjoyment of the website. You are responsible for maintaining the confidentiality of your account and password.')}
          </p>

          <h2 className="text-2xl font-semibold mb-4">{t('4. Intellectual Property')}</h2>
          <p className="mb-4">
            {t('The content generated through our service is intended for educational purposes. Users retain the rights to the specific exercises they create, but we retain the rights to the underlying technology and algorithms.')}
          </p>

          <h2 className="text-2xl font-semibold mb-4">{t('5. Privacy Policy')}</h2>
          <p className="mb-4">
            {t('Your use of the service is also governed by our Privacy Policy, which can be found on our website. By using this service, you consent to the collection and use of your information as described in the Privacy Policy.')}
          </p>

          <h2 className="text-2xl font-semibold mb-4">{t('6. Limitation of Liability')}</h2>
          <p className="mb-4">
            {t('We shall not be held liable for any direct, indirect, incidental, consequential or exemplary damages resulting from the use or inability to use the service.')}
          </p>

          <h2 className="text-2xl font-semibold mb-4">{t('7. Modifications to Terms')}</h2>
          <p className="mb-4">
            {t('We reserve the right to modify these terms at any time. Your continued use of the service after such modifications will constitute your acknowledgment of the modified terms.')}
          </p>

          <h2 className="text-2xl font-semibold mb-4">{t('8. Governing Law')}</h2>
          <p className="mb-4">
            {t('These terms and conditions are governed by and construed in accordance with the laws of France. Any disputes relating to these terms and conditions will be subject to the exclusive jurisdiction of the courts of France.')}
          </p>

          <h2 className="text-2xl font-semibold mb-4">{t('9. Contact Information')}</h2>
          <p className="mb-4">
            {t('If you have any questions about these Terms, please contact us at:')} {process.env.REACT_APP_EMAIL_ADDRESS}
          </p>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default TermsPage;