import React from "react";
import { useDrag } from "react-dnd";

const DraggableExercise = ({ exercise, children }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "EXERCISE",
    item: { id: exercise._id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
    //   style={{ opacity: isDragging ? 0.9 : 1 }}
      className={`draggable-exercise ${isDragging ? "dragging" : ""}`}
    >
      {children}
    </div>
  );
};

export default DraggableExercise;
