import React from 'react';

const NeoBrutalistCard = ({ children, interactable = true,className = '', ...props }) => {
  return (
    <div 
      className={`
        bg-white 
        border-4 
        border-black 
        rounded-none 
        shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] 
        p-6 
        transition-all 
        duration-300 
        
        ${interactable ? 'hover:shadow-[12px_12px_0px_0px_rgba(0,0,0,1)]  hover:-translate-x-1 hover:-translate-y-1' : ''}
        ${className}
      `}
      {...props}
    >
      {children}
    </div>
  );
};

export default NeoBrutalistCard;