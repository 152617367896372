import React from 'react';
import PropTypes from 'prop-types';

const PageTitle = ({ children }) => {
  return (
    <h1 className={`text-5xl font-extrabold mb-12 text-center text-gray-800 mt-8`}>
      <span className="bg-blue-600 text-white px-6 py-3 inline-block rounded-lg shadow-lg">
        {children}
      </span>
    </h1>
  );
};

export default PageTitle;