import React from 'react';
import { useNavigate } from 'react-router-dom';

const NeoBrutalistButton = ({ children, onClick, className, disabled, to, containerClassName, type = 'button', style = 'primary', size = 'medium', tooltip = '' }) => {
  const navigate = useNavigate(); // Initialize the navigation hook

  const getStyleClasses = () => {
   /* if(disabled){
      return 'opacity-50'
    }*/
    switch (style) {
      case 'secondary':
        return 'bg-gray-300' + (disabled ? '' : ' hover:bg-gray-400 active:bg-gray-500');
      case 'warning':
        return 'bg-orange-400' + (disabled ? '' : ' hover:bg-orange-500 active:bg-orange-600');
      case 'success':
        return 'bg-green-400' + (disabled ? '' : ' hover:bg-green-500 active:bg-green-600');
      case 'error':
        return 'bg-red-400' + (disabled ? '' : ' hover:bg-red-500 active:bg-red-600');
      case 'info':
        return 'bg-blue-400' + (disabled ? '' : ' hover:bg-blue-500 active:bg-blue-600 text-white');
      default:
        return 'bg-yellow-400' + (disabled ? '' : ' hover:bg-yellow-500 active:bg-yellow-600');
    }
  };

  const handleClick = () => {
    if (to) {
      navigate(to); // Redirect to the specified page if 'to' is not null
    }
    if (onClick) {
      onClick(); // Call the onClick function if provided
    }
  };

  if(tooltip){
  return ( 
    <div className={`relative inline-block group z-2 flex flex-1 ${containerClassName || ''}`}>
      <button
        type={type}
        onClick={handleClick}
        disabled={disabled}
        className={`px-6 ${size === 'xs' ? 'py-1' : 'py-3'} ${getStyleClasses()} text-black font-bold text-lg border-4 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] ${disabled ? '' : 'hover:shadow-[2px_2px_0px_0px_rgba(0,0,0,1)]'} active:shadow-none transition-all duration-150 ease-in-out whitespace-nowrap flex items-center justify-center rounded-lg transition-all ${size === 'small' ? 'text-sm' : 'text-lg'} ${className || ''}`}
      >
        {children}
      </button>
      {tooltip && (
        <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 text-sm text-white bg-black border-2 border-black shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] rounded-lg whitespace-nowrap opacity-0 group-hover:opacity-100 transition-opacity duration-200 z-4">
          {tooltip}
        </span>
      )}
    </div>    
  );
} else {
  return (
    <button
      type={type}
      onClick={handleClick}
      disabled={disabled}
      className={`px-3 md:px-6 ${size === 'xs' ? 'py-0' : 'py-3'} ${getStyleClasses()} text-black font-bold text-lg border-4 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] ${disabled ? 'opacity-70' : 'hover:shadow-[2px_2px_0px_0px_rgba(0,0,0,1)]'}  transition-all duration-150 ease-in-out whitespace-nowrap flex items-center justify-center rounded-lg ${disabled ? '' : 'hover:shadow-none hover:translate-x-1 hover:translate-y-1'} transition-all ${size === 'small' ? 'text-sm' : 'text-sm md:text-lg'} ${className || ''}`}
    >
      {children}
    </button>
  );
}
};

export default NeoBrutalistButton;