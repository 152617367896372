import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NeoBrutalistButton from "./NeoBrutalistButton";
import NeoBrutalistModal from "./NeoBrutalistModal";
import { axiosInstance } from "../axiosConfig";
import logger from "../utils/logger";
import { useNavigate } from "react-router-dom";

const PlansPriceTable = ({ currentPlanId, onSelectPlan, onCanceled }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const plans = [
    {
      id: "free",
      name: "Free",
      price: 0,
      features: [
        t("20 credits offered"),
        t('Freely buy credits whenever you want')
      ],
      buttonText: t("Get Started"),
      disabled: true,
    },
    {
      id: "basic",
      name: "Basic",
      price: process.env.REACT_APP_PLAN_BASIC_COST,
      features: [
        t(`{{credits}} credits per month ({{percentage}}% discount)`).replace('{{credits}}',process.env.REACT_APP_PLAN_BASIC_MENSUAL_CREDITS).replace('{{percentage}}',22),
        t("Unused credits roll over to the next month"),
        t("Premium support by email"),
      //  t("Access to main features:"),
        t("Custom exercise generation"),
        t("Automatic corrections"),
        t("Student management and grade tracking"),
      ],
      buttonText: t("Choose Basic Plan"),
    },
    {
      id: "pro",
      name: "Pro",
      price: process.env.REACT_APP_PLAN_PRO_COST,
      features: [
        t(`{{credits}} credits per month ({{percentage}}% discount)`).replace('{{credits}}',process.env.REACT_APP_PLAN_PRO_MENSUAL_CREDITS).replace('{{percentage}}',33),
        t("Unused credits roll over to the next month"),
        t("Premium support by email"),
     //   t("Access to main features:"),
        t("Custom exercise generation"),
        t("Automatic corrections"),
        t("Student management and grade tracking"), 
      ],
      buttonText: t("Choose Pro Plan"),
    },
  ];

  useEffect(() => {
    logger.log(currentPlanId);
  }, [currentPlanId]);

  const handleSubscribe = async (plan) => {

    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login", { state: { from: "/my-plan" } });
      return;
    }

  /*  if (
      (currentPlanId === "basic" || currentPlanId === "pro") &&
      plan.id === "free"
    ) {
      setSelectedPlan(plan);
      setShowCancelModal(true);
      return;
    }*/

    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/subscriptions/create-checkout-session`,
        { planId: plan.id },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if(response.data.sessionUrl){
      // Redirect to Stripe Checkout
      window.location.href = response.data.sessionUrl;
      } else {
        document.location.reload();
      }
    } catch (error) {
      logger.error("Error creating subscription:", error);
      setError(t("Error creating subscription"));
    }
  };

  const confirmCancelSubscription = async () => {
    setShowCancelModal(false);

    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/subscriptions/cancel`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      if (response.data.success) {
        logger.log("Subscription cancelled successfully");
        onCanceled();
      } else {
        logger.error("Failed to cancel subscription");
      //  setNotificationText(t("Failed to cancel subscription"));
        onCanceled();
      }
    } catch (error) {
      logger.error("Error cancelling subscription:", error);
      setError(t("Error cancelling subscription"));
    }
  };

  return (
    <div>
    <div className="flex flex-col lg:flex-row justify-center gap-8 p-8">
      {plans.map((plan) => (
        <div
          key={plan.name}
          className="flex flex-1 flex-col border-4 border-black p-6 bg-white shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] w-full lg:flex-1 mx-auto"
        >
          <h2 className="text-2xl font-bold mb-4">{plan.name}</h2>
          <p className="text-4xl font-bold mb-6">
            ${plan.price ? parseFloat(plan.price) .toFixed(2) : "0.00"}{" "}
            <span className="text-lg font-normal">/{t("month")}</span>
          </p>
          <ul className="mb-8 flex-grow">
            {plan.features.map((feature, index) => (
              <li key={index} className="mb-2 flex items-center">
                <span className="mr-2">✅</span> {feature}
              </li>
            ))}
          </ul>
          <NeoBrutalistButton
            onClick={() => handleSubscribe(plan)}
            disabled={plan.id === currentPlanId}
            style={plan.id === currentPlanId ? "secondary" : "primary"}
            className="w-full"
          >
            {plan.id === currentPlanId ? t("Current Plan") : plan.buttonText}
          </NeoBrutalistButton>
        </div>
      ))}
      {error && (
        <div className="text-red-500 bg-red-100 border-2 border-red-500 p-4 font-bold text-center max-w-[400px] mx-auto border-4 border-black shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
          {error}
        </div>
      )}
      <NeoBrutalistModal
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        title={t("Cancel Subscription")}
        size="sm"
      >
        <p>{t("Are you sure you want to cancel your subscription?")}</p>
        <div className="flex justify-end space-x-4 mt-4">
          <NeoBrutalistButton
            onClick={() => setShowCancelModal(false)}
            style="secondary"
          >
            {t("No")}
          </NeoBrutalistButton>
          <NeoBrutalistButton
            onClick={confirmCancelSubscription}
            style="primary"
          >
            {t("Yes")}
          </NeoBrutalistButton>
        </div>
      </NeoBrutalistModal>
    </div>
    {error && (
      <div className="text-red-500 bg-red-100 border-2 border-red-500 p-4 font-bold text-center max-w-[400px] mx-auto border-4 border-black shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
        {error}
      </div>
    )}
    </div>
  );
};

export default PlansPriceTable;
