import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import NeoBrutalistInput from "./NeoBrutalistInput";
import NeoBrutalistSelect from "./NeoBrutalistSelect";
import NeoBrutalistTextArea from "./NeoBrutalistTextArea";
import NeoBrutalistButton from "./NeoBrutalistButton";
import NeoBrutalistModal from "./NeoBrutalistModal";
import { CheckIcon, StopIcon, TrashIcon } from "@heroicons/react/solid";
import { FaMagic } from "react-icons/fa";
import ExerciseEditor from "./ExerciseEditor";
import { Tab } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import NeoBrutalistImageUpload from "./NeoBrutalistImageUpload"; // Assurez-vous d'importer le composant d'upload d'image
import axios from "axios"; // Importez axios si ce n'est pas déjà fait



const LessonEditor = ({ lesson, onSave, onClose }) => {
  const imageInputRef = useRef(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [editedLesson, setEditedLesson] = useState(lesson);
  const [isExerciseEditorOpen, setIsExerciseEditorOpen] = useState(false);
  const [currentExercise, setCurrentExercise] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openStepIndex, setOpenStepIndex] = useState(null);

  
  const handleImageClick = () => {
    imageInputRef.current.click();
  };
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('illustration', file);
      formData.append('lessonId', lesson._id);

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/lessons/uploadIllustration/${lesson._id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setEditedLesson((prev) => ({
          ...prev,
          illustration: response.data.illustrationUrl,
        }));
      } catch (error) {
        console.error('Erreur lors du téléchargement de l\'illustration:', error);
      }
    }
  };

  const toggleStep = (index) => {
    setOpenStepIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const handleDeleteStep = (index) => {
    const updatedSteps = editedLesson.steps.filter((_, i) => i !== index);
    setEditedLesson((prev) => ({ ...prev, steps: updatedSteps }));
    setOpenStepIndex(null);
  };

  const handleGenerateExercices = (lesson) => {
    console.log("generate exercices");
    navigate(`/generators?type=exercises&lesson=${lesson._id}`);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedLesson((prev) => ({ ...prev, [name]: value }));
  };

  const handleStepChange = (index, field, value) => {
    const updatedSteps = [...editedLesson.steps];
    updatedSteps[index] = { ...updatedSteps[index], [field]: value };
    setEditedLesson((prev) => ({ ...prev, steps: updatedSteps }));
  };

  const handleSave = () => {
    onSave(editedLesson);
  };

  const handleEditExercise = (exercise, index) => {
    setCurrentExercise({ ...exercise, index });
    setIsExerciseEditorOpen(true);
  };

  const handleSaveExercise = (updatedExercise) => {
    const updatedExercises = [...editedLesson.exercises];
    updatedExercises[currentExercise.index] = updatedExercise;
    setEditedLesson((prev) => ({ ...prev, exercises: updatedExercises }));
    setIsExerciseEditorOpen(false);
  };

  const modalFooter = (
    <div className="flex justify-end space-x-4">
      <NeoBrutalistButton onClick={() => handleGenerateExercices(lesson)} style="secondary" size="small">
        <FaMagic className="h-4 w-4 mr-2" />
        {t("Generate exercise for this lesson")}
      </NeoBrutalistButton>
      <NeoBrutalistButton onClick={onClose} style="secondary" size="small">
        <StopIcon className="h-4 w-4 mr-2" />
        {t("Cancel")}
      </NeoBrutalistButton>
      <NeoBrutalistButton onClick={handleSave} style="primary" size="small">
        <CheckIcon className="h-4 w-4 mr-2" />
        {t("Save")}
      </NeoBrutalistButton>
    </div>
  );

   return (
    <NeoBrutalistModal
      isOpen={true}
      onClose={onClose}
      title={t('Edit lesson')}
      footer={modalFooter}
    >
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List className="flex space-x-2 mb-4">
          <Tab
            className={({ selected }) =>
              `w-full py-2 text-lg font-bold border-4 border-black rounded-none transition-all
              ${selected
                ? "bg-yellow-400 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] text-black"
                : "bg-white hover:bg-yellow-200"
              }`
            }
          >
            {t("General")}
          </Tab>
          <Tab
            className={({ selected }) =>
              `w-full py-2 text-lg font-bold border-4 border-black rounded-none transition-all
              ${selected
                ? "bg-yellow-400 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] text-black"
                : "bg-white hover:bg-yellow-200"
              }`
            }
          >
            {t("Sections")}
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <NeoBrutalistInput
              label={t("Title")}
              name="title"
              value={editedLesson.title}
              onChange={handleInputChange}
            />
            <NeoBrutalistInput
              label={t("Subject")}
              name="subject"
              value={t(editedLesson.subject)}
              onChange={handleInputChange}
              options={JSON.parse(process.env.REACT_APP_SUBJECTS).map(subject => ({ value: subject, label: t(subject) }))}
            />
            <NeoBrutalistInput
              label={t("Level")}
              name="level"
              value={t(editedLesson.level)}
              onChange={handleInputChange}
              options={JSON.parse(process.env.REACT_APP_LEVELS).map(level => ({ value: level, label: t(level) }))}
            />
            <NeoBrutalistTextArea
              label={t("Description")}
              name="description"
              value={editedLesson.description}
              onChange={handleInputChange}
            />
             <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={imageInputRef}
              onChange={handleImageUpload}
            />
            {editedLesson.illustration && (
              <div className="mt-4">
                <h3 className="text-xl font-bold mb-2">{t("Illustration")}</h3>
                <img
                  src={editedLesson.illustration}
                  alt="Illustration de la leçon"
                  onClick={handleImageClick}
                  className="w-full max-w-md mx-auto border-4 border-black rounded-lg shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] cursor-pointer"
                />
              </div>
            )}
          </Tab.Panel>
          <Tab.Panel>
            {editedLesson.steps.map((step, index) => (
              <div key={index} className="mb-4">
                <div
                  className={`flex justify-between items-center p-4 border-4 border-black rounded-lg shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] cursor-pointer hover:bg-yellow-400 ${
                    openStepIndex === index ? "bg-yellow-400" : "bg-white"
                  }`}
                  onClick={() => toggleStep(index)}
                >
                  <h3 className="text-lg md:text-2xl font-bold">{t("Section")} {index + 1}</h3>
                  <span className="text-2xl ml-8">
                    {openStepIndex === index ? "▲" : "▼"}
                  </span>
                </div>
                {openStepIndex === index && (
                  <div className="mt-4 p-4 bg-grey-200 border-4 border-black rounded-lg shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
                    <NeoBrutalistInput
                      label={t("Section Title")}
                      name={`step-${index}-title`}
                      value={step.title}
                      onChange={(e) => handleStepChange(index, "title", e.target.value)}
                    />
                    <NeoBrutalistTextArea
                      label={t("Section Content")}
                      name={`step-${index}-content`}
                      value={step.content}
                      onChange={(e) => handleStepChange(index, "content", e.target.value)}
                    />
                     <div className="mt-4 flex justify-end">
                      <NeoBrutalistButton
                        onClick={() => handleDeleteStep(index)}
                        style="secondary"
                        size="small"
                      >
                        <TrashIcon className="h-4 w-4 mr-2" />
                        {t("Delete Section")}
                      </NeoBrutalistButton>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </NeoBrutalistModal>
  );
};

export default LessonEditor;