import React from 'react';
import { useTranslation } from 'react-i18next';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const StudentGradesChart = ({ grades, maxScore = 10 }) => {
  const { t } = useTranslation();

  
// ... code existant ...

const formatData = (grades) => {
  const dataByDate = {};
  grades.forEach(grade => {
    const key = new Date(grade.date).toLocaleDateString('en-EN', { year: 'numeric', month: 'long' });
    if (!dataByDate[key]) {
      dataByDate[key] = { date: key };
    }
    dataByDate[key][grade.subject] = grade.score;
    dataByDate[key][`${grade.subject}ExerciseCount`] = grade.exerciseCount;
  });
  return Object.values(dataByDate);
};

const data = formatData(grades);

const colors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#a4de6c'];

const subjects = [...new Set(grades.map(grade => grade.subject))];

const CustomTooltip = ({ active, payload, label, maxScore =10}) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white border-2 border-black p-2 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
        <p className="font-bold">{label}</p>
        {payload.map((entry, index) => (
          <p key={index} style={{ color: entry.color }}>
            {entry.dataKey.endsWith('ExerciseCount') ? ( 
              <p>
              
              </p>
            ) : (
              <p>
                {entry.name}: {entry.value.toFixed(0)+'/'+maxScore} 
                {entry.dataKey.endsWith('ExerciseCount') ? '' : ` (${payload[index + 1].value} ${t('exercises')})`}
              </p>
            )}
          
          </p>
        ))}
      </div>
    );
  }
  return null;
};
return (
  <div className="w-full h-64 md:h-96">
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis domain={[0, 'dataMax']} />
        <Tooltip content={<CustomTooltip />} />
        <Legend formatter={(value) => value.endsWith('ExerciseCount') ? null : value} />
        {subjects.map((subject, index) => (
          <React.Fragment key={subject}>
            <Line type="monotone" dataKey={subject} stroke={colors[index % colors.length]} 
                  strokeWidth={3} name={subject} />
            <Line type="monotone" dataKey={`${subject}ExerciseCount`} style={{ display: 'none' }} />
          </React.Fragment>
        ))}
        
      </LineChart>
    </ResponsiveContainer>
  </div>
);
};

export default StudentGradesChart;