// client/src/components/NeoBrutalistNotification.js
import React, { useState, useEffect } from 'react';

const NeoBrutalistNotification = ({ message, onClose, isOpen, autoClose=true }) => {
    const [show, setShow] = useState(isOpen);
    useEffect(() => {
        if (autoClose) {
            setTimeout(() => {
                setShow(false);
            }, 5000);
        }
    }, [autoClose]);

    useEffect(() => {
        setShow(isOpen);
    }, [isOpen]);

  return (
    <div className={`fixed bottom-4 right-4 bg-yellow-400 text-black font-bold px-4 py-2 pr-6 rounded-lg border-4 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] z-50 max-w-[320px] ${show ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>
      <p>{message}</p>
      <button 
        onClick={onClose}
        className="absolute top-1 right-2 text-black font-bold "
      >
        ✕
      </button>
    </div>
  );
};

export default NeoBrutalistNotification;