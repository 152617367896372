import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import logger from '../utils/logger';

const NeoBrutalistSelect = ({ label, name, value, onChange, options, error, className, fieldClassName, icon, labelOption= true, defaultLabelOptionText= "Select an option", readonly=false, size="medium" }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  const [labelOptionText, setLabelOptionText] = useState(defaultLabelOptionText);

  useEffect(() => {
     if(defaultLabelOptionText === "Select an option"){
      setLabelOptionText(t("Select an option"));
     } else {
      setLabelOptionText(defaultLabelOptionText);
     }
  }, []);

  useEffect(() => {
   logger.log('value',value);
  }, [value]);

 return (
    <div className={`mb-4 relative ${className}`}>
      <label htmlFor={name} className="block text-lg font-bold mb-2 text-black">
        {label}
      </label>
      <div
        className={`w-full px-4 py-2 text-black  border-4 ${error ? 'border-red-500' : 'border-black'} rounded-none shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] focus:outline-none focus:ring-4 focus:ring-yellow-400 focus:border-black transition-all cursor-pointer ${readonly ? 'cursor-default pointer-events-none' : 'cursor-pointer'} ${fieldClassName?fieldClassName:'bg-white'} ${size === 'small' ? 'text-sm' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
        data-value={value}
      >
        <div className='float-start me-2'>{icon}</div>
{value !== undefined && value !== null && value !== '' ? options.find(opt => opt.value == value)?.label : labelOption ? labelOptionText : options.length > 0 ? options[0].label : null}
</div>
      {isOpen && (
        <div className="absolute z-10 w-full mt-2 bg-white border-4 border-black rounded-none shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] min-w"
        style={{maxHeight: "280px", overflowY: "auto"}}>
          {labelOption && (
            <div
            key=""
              className={`px-4 py-2 hover:bg-yellow-200 cursor-pointer transition-colors ${size === 'small' ? 'text-sm' : ''}`}
              onClick={() => {
                onChange({ target: { name, value: '' } });
                setIsOpen(false);
              }}
            >
              <div className='float-start me-2'>{icon}</div>
              {labelOptionText}
            </div>
          )}
          {options.map((option, index) => (
            <div
              key={option.value}
              className={`px-4 py-2 hover:bg-yellow-200 cursor-pointer transition-colors ${size === 'small' ? 'text-sm' : ''}`}
              onClick={() => {
                onChange({ target: { name, value: option.value } });
                setIsOpen(false);
              }}
            >
              { index>0 && <div className='float-start me-2'>{icon}</div>}
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default NeoBrutalistSelect;