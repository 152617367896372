import React, { useState } from "react";
import NeoBrutalistButton from "./NeoBrutalistButton";
import { useTranslation } from "react-i18next";
import { axiosInstance } from "../axiosConfig";

const NeoBrutalistUploadField = ({
  label,
  name,
  onChange,
  onEstimate,
  estimate = false,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const { t } = useTranslation();

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
  
    setIsUploading(true);
    setSelectedFile(file);
    const formData = new FormData();
    formData.append('lessonFile', file);
  
    try {
      const response = await axiosInstance.post('/exercises/uploadAndEstimate', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      onChange(response.data.sessionId);
      if(onEstimate) {
        onEstimate(response.data.estimatedCredits);
      }
    } catch (error) {
      console.error('Erreur lors de l\'upload du fichier:', error);
      if(onEstimate) {
        onEstimate(0);
      }
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="mb-4">
      <label htmlFor={name} className="block text-lg font-bold mb-2 text-black">
        {label}
      </label>
      {selectedFile && <p className="mb-2">{selectedFile.name}</p>}
      <input
        type="file"
        id={name}
        name={name}
        accept=".pdf,.txt,.docx"
        onChange={handleFileChange}
        className="hidden"
      />
      <NeoBrutalistButton
        onClick={() => document.getElementById(name).click()}
        style="secondary"
        size="small"
      >
        {selectedFile ? t("Change file") : t("Upload file")}
      </NeoBrutalistButton>
    </div>
  );
};

export default NeoBrutalistUploadField;
