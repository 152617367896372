import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import NeoBrutalistButton from "./NeoBrutalistButton";
import HeaderLink from "./HeaderLink";
import { axiosInstance } from "../axiosConfig";
import { useTranslation } from "react-i18next";
import Logo from "./Logo";
import { useAuth } from "../contexts/AuthProvider";
import { LogoutIcon } from "@heroicons/react/solid";
import {
  FaBookOpen,
  FaCog,
  FaCoins,
  FaHome,
  FaKey,
  FaMagic,
  FaRocket,
  FaSignOutAlt,
  FaUserFriends,
} from "react-icons/fa";
import NotificationsDropdown from "./NotificationsDropdown";
import UserProfileDropdown from "./UserProfileDropdown";
import PurchaseCreditsModal from "./PurchaseCreditsModal";
import { useLocation } from "react-router-dom";

const Header = ({ onCreditsUpdate, credits }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { isAuthenticated, logout, userInfo } = useAuth();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);

  const isLinkActive = (path) => {
    return location.pathname === path;
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };



  return (
    <header
      className={`${
        isAuthenticated ? "bg-[#fafafa] " : "bg-[#ffcc66] pb-0 relative"
      } border-b-4 border-black overflow-hidden`}
    >
      {/* Menu du haut */}
      <div
        className={`md:flex justify-between  ${
          isAuthenticated ? "p-4 items-center" : "p-0 items-start"
        } pb-0 md:pb-0`}
      >
        <div className=" flex items-center space-x-4 gap-4">
          {isAuthenticated ? (
            <>
              <Logo className="h-8 w-8" />
            </>
          ) : (
            // <nav className="hidden md:flex space-x-4">
            //   <HeaderLink
            //     to="/"
            //     active={isLinkActive("/")}
            //     className="font-semibold hover:bg-yellow-400 px-4 py-2 rounded-md transition-all duration-200"
            //   >
            //     {t("Home")}
            //   </HeaderLink>
            //   <HeaderLink
            //     to="/pricing"
            //     active={isLinkActive("/pricing")}
            //     className="font-semibold hover:bg-yellow-400 px-4 py-2 rounded-md transition-all duration-200"
            //   >
            //     {t("Pricing")}
            //   </HeaderLink>
            //   <HeaderLink
            //     to="/documentation"
            //     active={isLinkActive("/documentation")}
            //     className="font-semibold hover:bg-yellow-400 px-4 py-2 rounded-md transition-all duration-200 opacity-50"
            //   >
            //     {t("Documentation")}
            //   </HeaderLink>
            //   <HeaderLink
            //     to="/contact"
            //     active={isLinkActive("/contact")}
            //     className="font-semibold hover:bg-yellow-400 px-4 py-2 rounded-md transition-all duration-200"
            //   >
            //     {t("Contact")}
            //   </HeaderLink>
            // </nav>
            <>
              <div class="diagonal-box">
                <a href="/" className="menuItem">
                  {t("Home")}
                </a>
                <a href="/pricing" className="menuItem">
                  {t("Pricing")}
                </a>
                <a href="/documentation" className="menuItem">
                  {t("Documentation")}
                </a>
                <a href="/about-us" className="menuItem">
                  {t("About us")}
                </a>
                <a href="/contact" className="menuItem">
                  {t("Contact")}
                </a>
              </div>

              <div>
                <div
                  id="circleLogo"
                  className="absolute top-28 left-1/2 transform  bg-yellow-400 p-12 rounded-full border-4 border-black -ml-[92px]"
                >
                  <Link to="/">
                    <img
                      src="/img/logo_pencil.png"
                      alt="Logo"
                      className="w-32 h-32"
                    />
                  </Link>
                </div>
                <img
                  src="/img/teacher_illus.webp"
                  id="teacherImg"
                  alt="Logo"
                  className="absolute bottom-0 left-1/2 transform -translate-x-1/2 ml-[348px] z-1 -mt-[120px] animate-slide-up"
                  />
                <img
                  src="/img/logo_large.webp"
                  id="logoLarge"
                  alt="Logo"
                  className="absolute bottom-[78px] left-1/2 transform -translate-x-1/2 mt-6 z-2"
                />
              </div>
            </>
          )}
        </div>
        <div className="text-right mt-4 md:mt-0">
          {isAuthenticated && userInfo && (
            <div className="flex flex-row items-center justify-center gap-4">
              <div className="flex space-x-4 items-end justify-end relative -top-1">
              
                <img
                  src="/img/icons/france.png"
                  alt="Français"
                  className="h-6 cursor-pointer hover:scale-110 transition-all duration-300"
                  onClick={() => changeLanguage("fr")}
                />
                <img
                  src="/img/icons/usa.png"
                  alt="English"
                  className="h-6 cursor-pointer hover:scale-110 transition-all duration-300"
                  onClick={() => changeLanguage("en")}
                />
              </div>

              <NotificationsDropdown />
              <div
                onClick={() => setIsPurchaseModalOpen(true)}
                className="cursor-pointer hover:scale-110 transition-all duration-300 text-sm ml-0 mb-2 font-semibold  md:block bg-yellow-400 text-black px-3 py-1 rounded-md border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]"
              >
                {credits}
                <FaCoins className="ml-2 inline-block" />
              </div>
              <div className="relative_ -top-1">
                <UserProfileDropdown />
              </div>
              <button
                className="lg:hidden text-black p-2 text-xl"
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              >
                ☰
              </button>
            </div>
          )}
          {!isAuthenticated && (
            <div className="flex space-x-4 items-end justify-end absolute md:relative top-2 right-2">
                <Link
                  to="/login"
                  className="font-semibold hover:bg-yellow-400 px-4 py-0 relative top-0 rounded-md transition-all duration-200"
                >
                  {t("Log in")}
                </Link>
              <img
                src="/img/icons/france.png"
                alt="Français"
                className="h-6 cursor-pointer hover:scale-110 transition-all duration-300"
                onClick={() => changeLanguage("fr")}
              />
              <img
                src="/img/icons/usa.png"
                alt="English"
                className="h-6 cursor-pointer hover:scale-110 transition-all duration-300"
                onClick={() => changeLanguage("en")}
              />
            </div>
          )}
        </div>
      </div>

      {!isAuthenticated ? (
        <>
          {/* Logo central et bannière */}
          {/* <div className="flex justify-center items-center my-4">
            <div className="flex items-center space-x-4">
              <div className="bg-yellow-400 p-6 rounded-full border-4 border-black">
                <Link to="/">
                  <img
                    src="/img/logo_pencil.png"
                    alt="Logo"
                    className="w-32 h-32"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center mb-10">
            <Logo className="h-16 w-16" />
          </div>
          <div className="flex justify-center items-center mb-10">
            <NeoBrutalistButton to="/login" className="text-black">
              <FaRocket className="w-6 h-6 mr-2" />
              {t("Get Started")}
            </NeoBrutalistButton>
          </div> */}
        </>
      ) : (
        <nav
          className={`${
            isMobileMenuOpen ? "block" : "hidden"
          } lg:flex justify-center md:space-x-8 bg-yellow-400 lg:bg-transparent p-4 lg:p-0 lg:mt-0 border-t-4 md:border-t-0 border-black`}
        >
          <HeaderLink
            to="/dashboard"
            active={isLinkActive("/dashboard")}
            onClick={() => setIsMobileMenuOpen(false)}
            className="font-semibold hover:bg-yellow-400 px-4 py-2 rounded-md transition-all duration-200 flex items-center"
          >
            <FaHome className="w-4 h-4 mr-2 hidden sm:block" />
            {t("Dashboard")}
          </HeaderLink>
          <HeaderLink
            to="/generators"
            active={isLinkActive("/generators")}
            onClick={() => setIsMobileMenuOpen(false)}
            className="font-semibold hover:bg-yellow-400 px-4 py-2 rounded-md transition-all duration-200 flex items-center"
          >
            <FaMagic className="w-4 h-4 mr-2 hidden sm:block" />
            {t("Generators")}
          </HeaderLink>
          {/* <HeaderLink
              to="/generator"
              active={isLinkActive("/generator")}
              onClick={() => setIsMobileMenuOpen(false)}
              className="font-semibold hover:bg-yellow-400 px-4 py-2 rounded-md transition-all duration-200 flex items-center"
            >
              <FaMagic className="w-4 h-4 mr-2 hidden sm:block" />
              {t("Exercise Generator")}
            </HeaderLink>
            <HeaderLink
            to="/lesson-generator"
            active={isLinkActive("/lesson-generator")}
            onClick={() => setIsMobileMenuOpen(false)}
            className="font-semibold hover:bg-yellow-400 px-4 py-2 rounded-md transition-all duration-200 flex items-center"
            >
            <FaBookOpen className="w-4 h-4 mr-2 hidden sm:block" />
            {t("Lesson Generator")}
            </HeaderLink> */}
          <HeaderLink
            to="/my-exercises"
            active={isLinkActive("/my-exercises")}
            onClick={() => setIsMobileMenuOpen(false)}
            className="font-semibold hover:bg-yellow-400 px-4 py-2 rounded-md transition-all duration-200 flex items-center"
          >
            <FaBookOpen className="w-4 h-4 mr-2 hidden sm:block" />
            {t("My Exercises")}
          </HeaderLink>
          <HeaderLink
            to="/my-lessons"
            active={isLinkActive("/my-lessons")}
            onClick={() => setIsMobileMenuOpen(false)}
            className="font-semibold hover:bg-yellow-400 px-4 py-2 rounded-md transition-all duration-200 flex items-center"
          >
            <FaBookOpen className="w-4 h-4 mr-2 hidden sm:block" />
            {t("My Lessons")}
          </HeaderLink>
          <HeaderLink
            to="/my-students"
            active={isLinkActive("/my-students")}
            onClick={() => setIsMobileMenuOpen(false)}
            className="font-semibold hover:bg-yellow-400 px-4 py-2 rounded-md transition-all duration-200 flex items-center"
          >
            <FaUserFriends className="w-4 h-4 mr-2 hidden sm:block" />
            {t("My Students")}
          </HeaderLink>

          {/* <HeaderLink
              to="/settings"
              active={activeLink === "settings"}
              className="font-semibold hover:bg-yellow-400 px-4 py-2 rounded-md transition-all duration-200 flex items-center"
            >
              <FaCog className="w-4 h-4 mr-2" />
              {t("Settings")}
            </HeaderLink> */}
        </nav>
      )}

      <PurchaseCreditsModal
        isOpen={isPurchaseModalOpen}
        onClose={() => setIsPurchaseModalOpen(false)}
        onCreditsUpdate={onCreditsUpdate}
      />
    </header>
  );
};

export default Header;
