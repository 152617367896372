import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NeoBrutalistButton from "../components/NeoBrutalistButton";
import NeoBrutalistInput from "../components/NeoBrutalistInput";

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");

    if(password.length < 6) {
      setError(t("Password must be at least 6 characters"));
      return;
    }

    if (password !== confirmPassword) {
      setError(t("Passwords do not match"));
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/reset-password`,
        { token, password }
      );
      setMessage(response.data.message);
      setTimeout(() => {
        window.location.href = "/login";
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      setError(t("Failed to reset password"));
    }
  };

  return (
    <div className="pt-12 bg-gray-100 flex items-center justify-center">
      <form className="bg-white p-6 rounded-lg shadow-md" onSubmit={handleSubmit}>
        <h2 className="text-2xl font-bold mb-4">{t("Reset Password")}</h2>
        <NeoBrutalistInput
          label={t("New Password")}
          name="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <NeoBrutalistInput
          label={t("Confirm Password")}
          name="confirmPassword"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <NeoBrutalistButton disabled={isLoading} type="submit" style="primary" className="w-full mt-4">
          {t("Reset")}
        </NeoBrutalistButton>
        {message && <div className="text-green-600 mt-4">{message}</div>}
        {error && <div className="text-red-600 mt-4">{error}</div>}
      </form>
    </div>
  );
};

export default ResetPasswordPage;