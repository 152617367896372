import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FaCommentAlt } from 'react-icons/fa';
import { useAuth } from "../contexts/AuthProvider";
import FeedbackModal from './FeedbackModal';


const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAuthenticated, logout, userInfo } = useAuth();
  const currentYear = new Date().getFullYear();
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  return (
    <footer className="relative bg-white border-t-4 border-black shadow-[0_-4px_0px_0px_rgba(0,0,0,1)] p-4">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        <div className="text-black font-bold mb-2 md:mb-0">
          © {currentYear} {process.env.REACT_APP_NAME} <i>by {' '}
          <Link to="https://loopus.tech" target="_blank" className="text-yellow-500 hover:text-yellow-400 transition-colors">Loopus</Link>
          </i>
        </div>
       <nav className="flex flex-col md:flex-row md:space-x-2 text-sm items-center">
          <a href="/terms" className="text-black hover:text-yellow-400 transition-colors block md:inline-block p-2 text-left">
            {t('Terms')}
          </a>
          <a href="/privacy" className="text-black hover:text-yellow-400 transition-colors block md:inline-block p-2 text-left">
            {t('Privacy')}
          </a>
          <a href="/legal" className="text-black hover:text-yellow-400 transition-colors block md:inline-block p-2 text-left">
            {t('Legal notice')}
          </a>
          {
            isAuthenticated && (          
          <a href="/documentation" className="text-black hover:text-yellow-400 transition-colors block md:inline-block p-2 text-left">
            {t('Documentation')}
          </a>
            )
          }
          <a href="/contact" className="text-black hover:text-yellow-400 transition-colors block md:inline-block p-2 text-left">
            {t('Contact')}
          </a>

        </nav> 
      </div>
      {isAuthenticated && (
        <>
      <button 
      onClick={() => setIsFeedbackModalOpen(true)}
        className="absolute bottom-4 right-4 bg-blue-500 hover:bg-blue-600 text-white rounded-full p-3 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transition-all duration-200 hover:translate-x-[-2px] hover:translate-y-[-2px] hover:shadow-[6px_6px_0px_0px_rgba(0,0,0,1)]"
        aria-label={t('Send Feedback')}
      >
        <FaCommentAlt size={20} />
      </button>
      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        onClose={() => setIsFeedbackModalOpen(false)}
      />
      </>
      )}
    </footer>
  );
};

export default Footer;