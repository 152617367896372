import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom'; // Add Navigate import
import Header from '../components/Header';
import Footer from '../components/Footer';
import NeoBrutalistButton from '../components/NeoBrutalistButton';
import { LightningBoltIcon, MailIcon, CheckCircleIcon, BriefcaseIcon, ChartBarIcon, GlobeIcon, LoginIcon, UserIcon } from '@heroicons/react/solid';
import { FaRocket } from 'react-icons/fa';
import { useAuth } from "../contexts/AuthProvider";
import Lottie from 'lottie-react';
import lottieVirtualEducation from "../lotties/virtualEducation.json";
import lottieNetwork from "../lotties/network.json";
import lottieAutomation from "../lotties/automation.json";
import lottieDownload from "../lotties/download.json";
import lottieStats from "../lotties/stats.json";
import lottieBooks from "../lotties/books.json";


const HomePage = () => {
  const { t } = useTranslation();
  const { isAuthenticated, logout } = useAuth();


  const features = [
    { lottie: lottieAutomation, icon: <LightningBoltIcon className="w-12 h-12" />, title: t('AI-Powered Content Creation'), description: t('Generate tailored lessons and exercises across all subjects and academic levels, including multiple-choice and open-ended questions.') },
    { lottie: lottieNetwork, icon: <MailIcon className="w-12 h-12" />, title: t('Automatic Distribution'), description: t('Send lessons and exercises to students via email with unique links. Receive notifications upon completion or full lesson reading.') },
    { lottie: lottieVirtualEducation, icon: <CheckCircleIcon className="w-12 h-12" />, title: t('Automatic Pre-correction'), description: t("Our AI analyzes students' answers to the exercises, and makes their full pre-correction, allowing you to check it and then send it to students in one click.") },
    { lottie: lottieDownload, icon: <BriefcaseIcon className="w-12 h-12" />, title: t('PDF Generation'), description: t('Generate lessons, exercises, and correction instructions in PDF format for easy distribution and printing.') },
    { lottie: lottieStats, icon: <ChartBarIcon className="w-12 h-12" />, title: t('Progress Tracking'), description: t('Monitor your students\' results and lesson engagement over time, with the ability to export data to CSV.') },
    { lottie: lottieBooks, icon: <GlobeIcon className="w-12 h-12" />, title: t('Lesson Upload'), description: t('Upload your own lessons in DOC or PDF format, or select from AI-generated lessons to create targeted exercises.') },
  ];
  return (
    <div className="min-h-screen bg-gray-200 flex flex-col">
      {/* <Header /> */}
      <main className="flex-grow container mx-auto px-4 py-0">
        <section className="text-center mb-4 py-8 pt-0">
          <h1 className="text-4xl md:text-6xl font-bold mb-4 text-black transform -rotate-2">
            <div className="bg-red-500 text-white px-4 py-2 inline-block transform rotate-0 relative lg:-top-[40px]">
              {t('Revolutionize')}
            </div><br/>
            <div className='transform rotate-2'>{t('Your Teaching')}</div>
          </h1>
          <p className="text-2xl mb-0 font-bold text-gray-800">
            {t('The best way to create, distribute and correct exercises with AI')}
          </p>
          {/* <div className="flex justify-center">
            <NeoBrutalistButton to="/register" size="large" className="transform rotate-2 hover:rotate-0 transition-transform">
            <FaRocket className="mr-2" />
              {t('Get Started for Free')}
            </NeoBrutalistButton>
            </div> */}
        </section>

      
        <section className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <div key={index} className="bg-white border-4 border-black p-6 shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] transform hover:-translate-y-2 transition-transform">
              {feature.lottie && (
                <div className='w-48 h-48 mx-auto'>
                <Lottie animationData={feature.lottie} loop={true} width={100} height={100} />
                </div>
              )}
              <h3 className="text-2xl font-bold mb-2 text-center">{feature.title}</h3>
              <p className='text-center'>{feature.description}</p>
            </div>
          ))}
        </section>
{/* 
        <section className="text-center mb-16">
          <h2 className="text-4xl font-bold mb-8 text-black transform -rotate-1">
            <span className="bg-blue-500 text-white px-4 py-2 inline-block transform rotate-1">
              {t('Ready to Transform')}
            </span> {t('Your Teaching Experience?')}
          </h2>
          <div className="flex justify-center">
        
              <NeoBrutalistButton to={isAuthenticated?"/generator":"/register"} size="large" style="secondary" className="transform -rotate-1 hover:rotate-0 transition-transform">
                <FaRocket className="mr-2" />
                {t('Join Now')}
              </NeoBrutalistButton>
            
            </div>
        </section> */}

          {/* New section added for the beta announcement */}
          <section className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4 text-black transform -rotate-1">
          <span className="bg-blue-500 text-white px-4 py-2 inline-block transform -rotate-1">
          {t('startFreeNow')} 
          </span>
          </h2>
          <p className="text-xl mb-8 text-gray-800 max-w-4xl mx-auto">
            {t('betaOfferDescription')}
          </p>
          <div className="flex justify-center">
        
              <NeoBrutalistButton to={isAuthenticated?"/dashboard":"/register"} size="large" style="secondary" className="transform -rotate-1 hover:rotate-0 transition-transform">
                <FaRocket className="mr-2" />
                {t('joinUsNow')}
              </NeoBrutalistButton>
            
            </div>
        </section>



      </main>
      <Footer />
    </div>
  );
};

export default HomePage;