import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import NeoBrutalistButton from '../components/NeoBrutalistButton';
import NeoBrutalistModal from '../components/NeoBrutalistModal';
import Footer from '../components/Footer';
import logger from '../utils/logger';

const StudentLessonPage = ({loadedLesson, isPreview, onClosePreview}) => {
  const { t } = useTranslation();
  const { uniqueLink } = useParams();
  const [lesson, setLesson] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [error, setError] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('url(/img/background1.webp)');
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    if (loadedLesson) {    
      setLesson(loadedLesson);
    } else {
      fetchLesson();
    }
  //  setRandomBackground();
  }, [uniqueLink]);

  const fetchLesson = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/lessons/${uniqueLink}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      console.log(response.data);
      setLesson(response.data);
    } catch (error) {
      logger.error("Erreur lors de la récupération de la leçon:", error);
      setError(t("The lesson could not be loaded. Please try again later."));
    }
  };


  const setRandomBackground = () => {
    const backgroundImages = ['background1.webp', 'background2.webp', 'background3.webp', 'background4.webp'];
    const randomImage = backgroundImages[Math.floor(Math.random() * backgroundImages.length)];
    setBackgroundImage(`url(/img/${randomImage})`);
  };


  
  const handleNextPage = async () => {
    if (currentStep < lesson.steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      if(!isPreview) {
        await axios.post(`${process.env.REACT_APP_API_URL}/lessons/${uniqueLink}/lessonRead`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          params: { since: "last_september" },
        });
      }
      setIsCompleted(true);
      if(isPreview){        
        setIsModalOpen(false);
      }
    }
  };

  const handlePreviousPage = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };


  const modalContent = () => {
    if (!lesson) return null;
    const currentStepContent = lesson.steps[currentStep];

    if (isCompleted) {
      return (
        <div className="text-center">
          <h2 className="text-4xl font-bold mb-4">{t('LESSON COMPLETED!')}</h2>
          <p className="text-xl mb-6">{t('You have finished this lesson. Congratulations!')}</p>
          <div className="border-4 border-black p-4 inline-block transform rotate-3 bg-green-400">
            <span className="text-2xl font-bold">🎉 {t('FINISHED!')} 🎉</span>
          </div>
        </div>
      );
    }

    return (
      <>
        <h1 className="text-3xl font-bold mb-6">{lesson.title}</h1>
        <div className="mb-6">
          <div className='flex justify-between gap-8 text-justify'>
            <img 
              src={lesson.illustration || '/img/default_illustration.webp'} 
              alt={currentStepContent.title} 
              className="max-h-64 mb-4 border-4 border-black"
            />
            <div>
              <h2 className="text-2xl font-semibold mb-4">{currentStepContent.title}</h2>
              <div>
                {
                currentStepContent.content.split('\n').map((paragraph, index) => (
                  <p className='mb-4' key={index}>{paragraph}</p>
                ))
                }
                </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <NeoBrutalistButton 
            onClick={handlePreviousPage} 
            disabled={currentStep === 0}
            style="secondary"
          >
            {t('Previous')}
          </NeoBrutalistButton>
          <NeoBrutalistButton onClick={handleNextPage}>
            {currentStep < lesson.steps.length - 1 ? t('Next') : t('Finish')}
          </NeoBrutalistButton>
        </div>
      </>
    );
  };


  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <>
      <div style={{
        backgroundImage: backgroundImage,
        backgroundSize: 'cover',
        backgroundRepeat: 'repeat',
        backgroundPosition: 'center',
        minHeight: isPreview ? 'auto' : '100vh',
      }}>
        <NeoBrutalistModal
          isOpen={isModalOpen}
          onClose={isPreview ? onClosePreview : () => setIsModalOpen(false)}
          closable={isPreview ? true : false}
          size='lg'
          title={`${t('Here is your lesson')}`}
        >
          {modalContent()}
        </NeoBrutalistModal>
      </div>
      {!isPreview && <Footer />}
    </>
  );
};

export default StudentLessonPage;