import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthProvider';
import NeoBrutalistButton from './NeoBrutalistButton';
import { useTranslation } from 'react-i18next';
import { FaUser, FaCog, FaSignOutAlt, FaMoneyBill, FaBicycle, FaBox, FaIdCard, FaDollarSign } from 'react-icons/fa';

const UserProfileDropdown = () => {
  const { t } = useTranslation();
  const { userInfo, logout } = useAuth();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative_" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="flex items-center space-x-2 bg-white_ border-0 border-gray-300 px-2 py-2 rounded-md shadow-sm_ hover:bg-gray-50 transition-colors duration-200"
      >
         {userInfo && userInfo.avatar ? (
              <img
                src={'/img/avatars/'+userInfo.avatar}
                alt="Avatar de l'utilisateur"
                className="w-10 h-10 rounded-full border-2 border-black transition-all duration-300 hover:scale-110"
              />
            ) : (
              <div className="w-8 h-8 bg-yellow-400 rounded-full flex items-center justify-center">
              <FaUser className="w-10 h-10 text-gray-500" />
              </div>
            )}
        <span className="font-semibold text-gray-700 text-sm hidden xl:block">{userInfo.name}</span>
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-full md:w-56 bg-white border border-gray-200 rounded-md shadow-lg z-20">
          <div className="py-1">
            <button
              onClick={() => navigate('/profile')}
              className="w-full text-left px-4 py-2 flex items-center space-x-2 hover:bg-gray-100 transition-colors"
            >
              <FaUser className="w-5 h-5 text-gray-500" />
              <span className="text-gray-700">{t('My Profile')}</span>
            </button>
            <button
              onClick={() => navigate('/my-plan')}
              className="w-full text-left px-4 py-2 flex items-center space-x-2 hover:bg-gray-100 transition-colors"
            >
              <FaDollarSign className="w-5 h-5 text-gray-500" />
              <span className="text-gray-700">{t('My Plan')}</span>
            </button>
            <button
              onClick={() => navigate('/settings')}
              className="w-full text-left px-4 py-2 flex items-center space-x-2 hover:bg-gray-100 transition-colors"
            >
              <FaCog className="w-5 h-5 text-gray-500" />
              <span className="text-gray-700">{t('Settings')}</span>
            </button>
            <hr className="my-1 border-gray-200" />
            <button
              onClick={handleLogout}
              className="w-full text-left px-4 py-2 flex items-center space-x-2 hover:bg-gray-100 transition-colors"
            >
              <FaSignOutAlt className="w-5 h-5 text-gray-500" />
              <span className="text-gray-700">{t('Logout')}</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfileDropdown;