import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Header from "../components/Header";
import NeoBrutalistButton from "../components/NeoBrutalistButton";
import {
  PencilIcon,
  TrashIcon,
  DownloadIcon,
  PaperAirplaneIcon,
  ClipboardCheckIcon,
  PlusIcon,
  FolderIcon,
  EyeIcon,
  CheckIcon,
} from "@heroicons/react/solid";
import ExerciseEditor from "../components/ExerciseEditor";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SendExerciseModal from "../components/SendExerciseModal"; // Nouveau composant à créer
import ExerciseCorrection from "../components/ExerciseCorrection";
import NeoBrutalistNotification from "../components/NeoBrutalistNotification";
import { axiosInstance } from "../axiosConfig";
import Footer from "../components/Footer";
import logger from "../utils/logger";
import NeoBrutalistSelect from "../components/NeoBrutalistSelect";
import NeoBrutalistInput from "../components/NeoBrutalistInput";
import NeoBrutalistModal from "../components/NeoBrutalistModal";
import ViewGridIcon from "../components/ViewGridIcon";
import ViewListIcon from "../components/ViewListIcon";
import DraggableExercise from "../components/DraggableExercise";
import DroppableFolder from "../components/DroppableFolder";
import StudentExercisePage from "./StudentExercisePage";
import { FaRocket, FaTrash } from "react-icons/fa";

const MyExercisesPage = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [exercises, setExercises] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingExercise, setEditingExercise] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const deleteButtonRef = useRef(null); // Create a ref for the delete button
  const [sendModalOpen, setSendModalOpen] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [isCorrecting, setIsCorrecting] = useState(false);
  const [correctingExercise, setCorrectingExercise] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [viewMode, setViewMode] = useState("grid");
  const [filters, setFilters] = useState({ subject: "", level: "", type: "" });
  const [folders, setFolders] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [isAddFolderModalOpen, setIsAddFolderModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [activeFolder, setActiveFolder] = useState(null);
  const [previewExercise, setPreviewExercise] = useState(null);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [selectedExercises, setSelectedExercises] = useState([]);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
    const [pendingCorrections, setPendingCorrections] = useState({});


  useEffect(() => {
    // Focus on the delete button when delete confirmation is shown
    if (deleteConfirmation) {
      deleteButtonRef.current?.focus();
      const handleKeyDown = (e) => {
        if (e.key === "Enter") {
          confirmDelete();
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [deleteConfirmation]);

  useEffect(() => {
    fetchExercises();
    fetchFolders();
  }, [filters, currentFolder]);

  useEffect(() => {
    if (editingExercise != null && exercises) {
      setEditingExercise(
        exercises.find((ex) => ex._id === editingExercise._id)
      );
    }
  }, [exercises]);

  useEffect(() => {
    const exerciseIdToEdit = searchParams.get("exerciseId");
    if (exerciseIdToEdit) {
      const exerciceToEdit = exercises.find(
        (lesson) => lesson._id === exerciseIdToEdit
      );
      if (exerciceToEdit) {
        const exerciseElement = document.getElementById(
          `exercise-${exerciceToEdit._id}`
        );
        if (exerciseElement) {
          exerciseElement.classList.add("bounce-animation");
          setTimeout(() => {
            exerciseElement.classList.remove("bounce-animation");
          }, 3000);
        }
        const searchParamsWithoutLessonId = new URLSearchParams(searchParams);
        searchParamsWithoutLessonId.delete("exerciseId");
        navigate({
          search: searchParamsWithoutLessonId.toString(),
        });
      }
    }
  }, [exercises, searchParams]);

  useEffect(() => {
    // Vérifier si un nouvel exercise a été créé
    if (location.state && location.state.newExerciseId) {
      const newExerciseId = location.state.newExerciseId;
      handleEdit(exercises.find((ex) => ex._id === newExerciseId));
      // Nettoyer l'état de location pour éviter de rouvrir l'éditeur lors des rechargements
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location, exercises]);

  useEffect(() => {
    fetchPendingCorrectionsForEachExercise();
  }, []);

const fetchPendingCorrectionsForEachExercise = async () => {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/exercise-reply/pending-corrections`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    const correctionsMap = response.data.reduce((acc, { _id, count }) => {
      acc[_id] = count;
      return acc;
    }, {});
    setPendingCorrections(correctionsMap);
  } catch (error) {
    logger.error("Erreur lors de la récupération des corrections en attente pour chaque exercice:", error);
  }
};

  const toggleExerciseSelection = (exerciseId) => {
    setSelectedExercises((prevSelected) =>
      prevSelected.includes(exerciseId)
        ? prevSelected.filter((id) => id !== exerciseId)
        : [...prevSelected, exerciseId]
    );
  };

  const handleExerciseClick = (exerciseId, event) => {
    if (event.target.tagName !== "BUTTON" && event.target.tagName !== "INPUT") {
      toggleExerciseSelection(exerciseId);
    }
  };

  const handleSelectAll = () => {
    if (selectedExercises.length === exercises.length) {
      setSelectedExercises([]);
    } else {
      setSelectedExercises(exercises.map((exercise) => exercise._id));
    }
  };

  const handleRemoveAllSelected = async () => {
    setIsDeleteConfirmationOpen(false);
    try {
      await Promise.all(
        selectedExercises.map((exerciseId) =>
          axiosInstance.delete(
            `${process.env.REACT_APP_API_URL}/exercises/${exerciseId}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
        )
      );
      fetchExercises();
      setSelectedExercises([]);
    } catch (error) {
      logger.error(
        "Erreur lors de la suppression des exercices sélectionnés:",
        error
      );
    }
  };

  const confirmRemoveAllSelected = () => {
    setIsDeleteConfirmationOpen(true);
  };

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [filterType]: value }));
  };

  const onDragEnd = (result) => {
    const { destination, draggableId } = result;

    if (!destination) {
      return;
    }

    const newFolderId =
      destination.droppableId === "exercises" ? null : destination.droppableId;

    handleMoveExercise(draggableId, newFolderId);
  };

  const handlePreview = (exercise) => {
    setPreviewExercise(exercise);
    setIsPreviewModalOpen(true);
  };

  const handleAddFolder = async () => {
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/folders`,
        { name: newFolderName, type: "exercise" },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setFolders([...folders, response.data]);
      setIsAddFolderModalOpen(false);
      setNewFolderName("");
    } catch (error) {
      logger.error("Erreur lors de la création du dossier:", error);
    }
  };

  const handleDeleteFolder = async (folderId) => {
    try {
      await axiosInstance.delete(
        `${process.env.REACT_APP_API_URL}/folders/${folderId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setFolders(folders.filter((folder) => folder._id !== folderId));
      setExercises(
        exercises.map((ex) =>
          ex.folder === folderId ? { ...ex, folder: null } : ex
        )
      );
    } catch (error) {
      logger.error("Erreur lors de la suppression du dossier:", error);
    }
  };

  const handleMoveExercise = async (exerciseId, folderId) => {
    try {
      await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/exercises/${exerciseId}/move`,
        { folderId },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setExercises((prevExercises) =>
        prevExercises.map((ex) =>
          ex._id === exerciseId ? { ...ex, folder: folderId } : ex
        )
      );
      fetchExercises();
    } catch (error) {
      logger.error("Erreur lors du déplacement de l'exercice:", error);
    }
  };
  const handleFolderClick = (folderId) => {
    if (activeFolder === folderId) {
      // Si le dossier cliqué est déjà actif, on le désélectionne
      setActiveFolder(null);
      setCurrentFolder(null);
    } else {
      // Sinon, on active le nouveau dossier
      setActiveFolder(folderId);
      setCurrentFolder(folderId);
    }
  };

  const fetchExercises = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/exercises/my-exercises`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          params: { ...filters, folderId: currentFolder }, // Ajout des filtres dans les paramètres de la requête
        }
      );
      setExercises(response.data);
    } catch (error) {
      logger.error("Erreur lors de la récupération des exercises:", error);
    }
  };
  const fetchFolders = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/folders?type=exercise`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setFolders(response.data);
    } catch (error) {
      logger.error("Erreur lors de la récupération des dossiers:", error);
    }
  };

  const handleEdit = (exercise) => {
    setEditingExercise(exercise);
    setIsEditing(true);
  };
  const handleSaveEdit = async (editedExercise) => {
    try {
      const exerciseElement = document.getElementById(
        `exercise-${editedExercise._id}`
      );
      if (exerciseElement) {
        exerciseElement.classList.add("bounce-animation");
        setTimeout(() => {
          exerciseElement.classList.remove("bounce-animation");
        }, 3000); // Remove the class after 3 seconds
      }

      await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/exercises/${editedExercise._id}`,
        editedExercise,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      setIsEditing(false);
      setEditingExercise(null);
      fetchExercises();

      // Add animation class to the updated exercise
    } catch (error) {
      logger.error("Erreur lors de la mise à jour de l'exercise:", error);
    }
  };
  const handleCloseEdit = () => {
    setIsEditing(false);
    setEditingExercise(null);
  };
  const handleDelete = (id) => {
    setDeleteConfirmation(id);
  };

  const confirmDelete = async () => {
    if (deleteConfirmation) {
      try {
        await axiosInstance.delete(
          `${process.env.REACT_APP_API_URL}/exercises/${deleteConfirmation}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        fetchExercises();
        setDeleteConfirmation(null);
      } catch (error) {
        logger.error("Erreur lors de la suppression de l'exercise:", error);
      }
    }
  };

  const cancelDelete = () => {
    setDeleteConfirmation(null);
    setIsDeleteConfirmationOpen(false);
  };

  const handleDownload = async (exercise) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/exercises/${exercise._id}/download`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      const formattedFileName = exercise.title
        .replace(/[éèêëÉÈÊË]/g, "e")
        .replace(/[îïÎÏ]/g, "i")
        .replace(/[àâÀÂ]/g, "a")
        .replace(/[ôÔ]/g, "o")
        .replace(/[ûùÛÙ]/g, "u")
        .replace(/[çÇ]/g, "c")
        .replace(/[^a-z0-9]/gi, "_")
        .toLowerCase();
      link.download = `${formattedFileName}.pdf`;
      link.click();
    } catch (error) {
      logger.error("Erreur lors du téléchargement du PDF:", error);
    }
  };

  const handleSend = (exercise) => {
    setSelectedExercise(exercise);
    setSendModalOpen(true);
  };

  const handleExerciseSent = (exerciseId, sentToStudents) => {
    // Mettre à jour l'état local si nécessaire
    logger.log(
      `Exercise ${exerciseId} sent to ${sentToStudents.length} students`
    );
    // Vous pouvez également rafraîchir la liste des exercises ici si nécessaire
    fetchExercises();
  };
  const handleCorrection = (exercise) => {
    setCorrectingExercise(exercise);
    setIsCorrecting(true);
  };

  const handleAllCorrected = () => {
    setNotificationMessage(t("All replies have been corrected"));
    setShowNotification(true);
    fetchExercises();
  };

  const handleAutoCorrected = () => {
    setNotificationMessage(t("The exercise has been auto-corrected"));
    setShowNotification(true);
    fetchExercises();
  };

  const handleCloseSendModal = () => {
    setSendModalOpen(false);
    setSelectedExercise(null);
    fetchExercises();
  };

  const getExoTypeName = (type) => {
    if (type === "fill_in_the_blanks") {
      return t("Fill in the blanks");
    } else if (type === "matching") {
      return t("Matching");
    } else if (type === "multiple_choice") {
      return t("Multiple Choice");
    } else if (type === "true_false") {
      return t("True/False");
    } else if (type === "flashcards") {
      return t("Flashcards");
    } else if (type === "free_writing") {
      return t("Free Writing");
    }
  };

  return (
    <>
      {/* <Header activeLink="my-exercises" /> */}
      <div className="min-h-screen bg-gray-200 px-4 py-8">
        <div className="container mx-auto px-4 pt-0 pb-8">
          {/* <h1 className="text-3xl md:text-4xl font-bold mb-8 text-center text-black border-b-4 border-black pb-2">
            {t("My Exercises")} ({exercises.length})
          </h1> */}
          <div className="mb-4 flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
            <div className="flex flex-wrap gap-2 w-full sm:w-auto">
              <NeoBrutalistSelect
                labelOption={false}
                options={[
                  { value: "", label: t("All Subjects") },
                  ...JSON.parse(process.env.REACT_APP_SUBJECTS).map(
                    (subject) => ({ value: subject, label: t(subject) })
                  ),
                  { value: "other", label: t("Other") },
                ]}
                value={filters.subject}
                onChange={(e) => handleFilterChange("subject", e.target.value)}
                className="w-full sm:w-auto"
              />
              <NeoBrutalistSelect
                labelOption={false}
                options={[
                  { value: "", label: t("All Levels") }, // Ajout de l'option "All Levels"
                  ...JSON.parse(process.env.REACT_APP_LEVELS).map((level) => ({
                    value: level,
                    label: t(level),
                  })),
                  { value: "other", label: t("Other") },
                ]}
                value={filters.level}
                onChange={(e) => handleFilterChange("level", e.target.value)}
                className="w-full sm:w-auto"
              />
              <NeoBrutalistSelect
                labelOption={false}
                options={[
                  { value: "", label: t("All Types") },
                  { value: "multiple_choice", label: t("Multiple Choice") },
                  {
                    value: "fill_in_the_blanks",
                    label: t("Fill in the blanks"),
                  },
                  { value: "matching", label: t("Matching") },
                  { value: "free_writing", label: t("Free Writing") },
                ]}
                value={filters.type}
                onChange={(e) => handleFilterChange("type", e.target.value)}
                className="w-full sm:w-auto"
              />
              {/* <NeoBrutalistButton
                size="small"
                style="secondary"
                onClick={() => setIsAddFolderModalOpen(true)}
                className="w-full sm:w-auto sm:my-3 sm:relative sm:-top-1"
              >
                <PlusIcon className="h-4 w-4 inline mr-2" />
                {t("Add Folder")}
              </NeoBrutalistButton> */}

              <NeoBrutalistButton
                onClick={() => navigate("/generators?type=exercise")}
                size="small"
                className="w-full sm:w-auto sm:my-3 sm:relative sm:-top-1"
              >
                <FaRocket className="w-4 h-4 mr-2" />
                {t("Create exercise")}
              </NeoBrutalistButton>
            </div>
            <div className="hidden md:flex flex space-x-2 w-full sm:w-auto justify-center sm:justify-start">
              <NeoBrutalistButton
                onClick={() => setViewMode("grid")}
                style={viewMode === "grid" ? "primary" : "secondary"}
              >
                <ViewGridIcon className="h-5 w-5" />
              </NeoBrutalistButton>
              <NeoBrutalistButton
                onClick={() => setViewMode("list")}
                style={viewMode === "list" ? "primary" : "secondary"}
              >
                <ViewListIcon className="h-5 w-5" />
              </NeoBrutalistButton>
            </div>
          </div>

          {folders.length > -1 && (
            <div className="mb-8">
              <h2 className="text-2xl font-bold mb-4">{t("Folders")}</h2>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
                {folders.map((folder) => (
                  <DroppableFolder
                    key={folder._id}
                    folder={folder}
                    // onDrop={handleMoveExercise}
                    onDelete={handleDeleteFolder}
                  >
                    <div
                      onClick={() => handleFolderClick(folder._id)}
                      className={`cursor-pointer transition-all duration-200 ${
                        activeFolder === folder._id
                          ? "bg-yellow-200 border-yellow-400"
                          : "bg-white hover:bg-gray-100 border-gray-300"
                      } border-2 rounded-lg p-4 flex flex-col items-center shadow-md`}
                    >
                      <svg
                        className={`w-12 h-12 mb-2 ${
                          activeFolder === folder._id
                            ? "text-yellow-600"
                            : "text-gray-500"
                        }`}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
                      </svg>
                      <span
                        className={`text-sm font-medium text-center ${
                          activeFolder === folder._id
                            ? "text-yellow-800"
                            : "text-gray-700"
                        }`}
                      >
                        {folder.name}
                      </span>
                    </div>
                  </DroppableFolder>
                ))}
                <div
                  onClick={() => setIsAddFolderModalOpen(true)}
                  className="cursor-pointer bg-gray-100 hover:bg-gray-200 border-2 border-dashed border-gray-300 rounded-lg p-4 flex flex-col items-center justify-center"
                >
                  <PlusIcon className="w-8 h-8 mb-2 text-gray-500" />
                  <span className="text-sm font-medium text-gray-500">
                    {t("Add Folder")}
                  </span>
                </div>
              </div>
            </div>
          )}

          {/* ... existing code ... */}
          {viewMode === "list" && (
            <div
              className="flex items-center mb-4 cursor-pointer"
              onClick={handleSelectAll}
            >
              <input
                type="checkbox"
                checked={selectedExercises.length === exercises.length}
                onChange={handleSelectAll}
                className="mr-2 neo-checkbox ml-5"
              />
              <span>{t("Select All")}</span>
            </div>
          )}

          <div
            className={
              viewMode === "grid"
                ? "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 md:mt-14"
                : "space-y-4 md:mt-4"
            }
          >
            {exercises.map((exercise) => (
              <DraggableExercise key={exercise._id} exercise={exercise}>
                <div
                  id={`exercise-${exercise._id}`}
                  className={`bg-white border-4 border-black rounded-none shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] transition-all duration-300 relative ${
                    viewMode === "list"
                      ? "p-4 flex items-center space-x-4"
                      : "p-6"
                  }`}
                  onClick={(e) => handleExerciseClick(exercise._id, e)}
                >
                  {viewMode === "list" && (
                    <input
                      type="checkbox"
                      checked={selectedExercises.includes(exercise._id)}
                      onChange={() => toggleExerciseSelection(exercise._id)}
                      className="mr-2 neo-checkbox"
                    />
                  )}
                  {viewMode === "list" ? (
                    <>
                      <div className="flex-shrink-0 w-24 h-24 relative">
                        <img
                          src={
                            exercise.illustration ||
                            process.env.REACT_APP_URL +
                              "/img/default_illustration.webp"
                          }
                          alt={exercise.title}
                          className="w-full h-full object-cover"
                        />
                        <div className="absolute -top-2 -right-2 transform rotate-3 z-1">
                          <div className="bg-blue-500 text-white border-2 border-black px-2 py-1 text-sm font-bold shadow-[2px_2px_0px_0px_rgba(0,0,0,1)]">
                            {t(exercise.subject)}
                          </div>
                        </div>
                      </div>
                      <div className="flex-grow">
                        <h2 className="text-xl font-bold text-black truncate">
                          {exercise.title}
                        </h2>
                        <p className="text-gray-600 text-sm mb-2 line-clamp-2">
                          {exercise.description}
                        </p>
                        <div className="flex items-center space-x-2 text-sm">
                          <span className="font-semibold">{t("Type")}:</span>
                          <span>{getExoTypeName(exercise.type)}</span>
                          <span className="font-semibold ml-4">
                            {t("Level")}:
                          </span>
                          <span>{t(exercise.level)}</span>
                        </div>
                      </div>
                      <div className="flex-shrink-0 flex space-x-2">
                        <NeoBrutalistButton
                          onClick={() => handleEdit(exercise)}
                          style="secondary"
                          size="small"
                          tooltip={t("Edit")}
                          containerClassName="z-10"
                        >
                          <PencilIcon className="h-4 w-4" />
                        </NeoBrutalistButton>
                        <NeoBrutalistButton
                          onClick={() => handlePreview(exercise)}
                          style="secondary"
                          size="small"
                          containerClassName="z-10"
                          tooltip={t("Preview")}
                        >
                          <EyeIcon className="h-4 w-4" />
                        </NeoBrutalistButton>
                        <NeoBrutalistButton
                          onClick={() => handleDownload(exercise)}
                          style="secondary"
                          size="small"
                          containerClassName="z-10"
                          tooltip={t("Download")}
                        >
                          <DownloadIcon className="h-4 w-4" />
                        </NeoBrutalistButton>
                        <NeoBrutalistButton
                          onClick={() => handleSend(exercise)}
                          style={
                            exercise.sentToStudents ? "secondary" : "primary"
                          }
                          size="small"
                          containerClassName="z-10"
                          tooltip={t("Send")}
                        >
                          <PaperAirplaneIcon className="h-4 w-4" />
                        </NeoBrutalistButton>
                        {exercise.sentToStudents && (
                          <NeoBrutalistButton
                            onClick={() => handleCorrection(exercise)}
                            style="primary"
                            size="small"
                            containerClassName="z-10"
                            tooltip={t("Corrections")}
                          >
                            <ClipboardCheckIcon className="h-4 w-4" />                            
                            {pendingCorrections[exercise._id] && (
                              <span className="absolute -top-2 -right-3 bg-blue-500 border-2 border-black rounded-full pt-1 shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] hover:shadow-[1px_1px_0px_0px_rgba(0,0,0,1)] transition-all duration-200 cursor-pointer z-1 w-8 h-8 text-sm">{pendingCorrections[exercise._id]}</span>
                               )}
                          </NeoBrutalistButton>
                        )}
                      </div>
                      <div
                        className="absolute -top-2 -right-3 bg-red-500 border-2 border-black rounded-full p-2 shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] hover:shadow-[1px_1px_0px_0px_rgba(0,0,0,1)] transition-all duration-200 cursor-pointer z-1 "
                        onClick={() => handleDelete(exercise._id)}
                      >
                        <TrashIcon className="h-4 w-4 text-white" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="absolute -top-3 -right-3 transform rotate-3 z-1">
                        <div className="bg-blue-500 text-white border-2 border-black px-2 py-2 text-lg font-bold shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
                          {
                          t(exercise.subject)
                          }
                        </div>
                      </div>
                      <div
                        className="absolute top-2 left-2 bg-red-500 border-2 border-black rounded-full p-1 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] transition-all duration-200 cursor-pointer"
                        onClick={() => handleDelete(exercise._id)}
                      >
                        <TrashIcon className="h-6 w-6 text-white" />
                      </div>
                      <img
                        src={
                          exercise.illustration
                            ? exercise.illustration
                            : process.env.REACT_APP_URL +
                              "/img/default_illustration.webp"
                        }
                        alt={exercise.title}
                        className="w-full h-32 object-cover mb-4"
                      />
                      <h2 className="text-xl font-bold mb-4 text-black h-8 overflow-hidden">
                        {exercise.title}
                      </h2>
                      <p className="text-gray-700 mb-4 md:h-24 overflow-y-auto text-sm">
                        {exercise.description}
                      </p>
                      <div className="bg-gray-200 border-2 border-gray-400 p-3 rounded-md mb-4 shadow-md text-sm">
                        <div className="mb-2">
                          <span className="font-bold text-gray-800">
                            {t("Type of Exercise")}:{" "}
                          </span>
                          <span className="text-gray-600">
                            {getExoTypeName(exercise.type)}
                          </span>
                        </div>
                        <div>
                          <span className="font-bold text-gray-800">
                            {t("Level")}:{" "}
                          </span>
                          <span className="text-gray-600">
                            {t(exercise.level)}
                          </span>
                        </div>
                      </div>
                      {folders.length > 0 && (
                        <NeoBrutalistSelect
                          icon={<FolderIcon className="h-6 w-6" />}
                          labelOption={false}
                          size="small"
                          value={exercise.folder}
                          options={[
                            { value: "", label: t("Move to Folder") },
                            ...folders.map((folder) => ({
                              value: folder._id,
                              label: folder.name,
                            })),
                          ]}
                          onChange={(e) =>
                            handleMoveExercise(exercise._id, e.target.value)
                          }
                        />
                      )}

                      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-2">
                        <NeoBrutalistButton
                          onClick={() => handleEdit(exercise)}
                          style="secondary"
                          size="small"
                          className="flex-1"
                        >
                          <PencilIcon className="h-6 w-6 mr-2" />
                          {t("Edit")}
                        </NeoBrutalistButton>
                        <NeoBrutalistButton
                          onClick={() => handlePreview(exercise)}
                          style="secondary"
                          size="small"
                          className="flex-1"
                        >
                          <EyeIcon className="h-6 w-6 mr-2" />
                          {t("Preview")}
                        </NeoBrutalistButton>
                        <NeoBrutalistButton
                          onClick={() => handleDownload(exercise)}
                          style="secondary"
                          size="small"
                          className="flex-1"
                        >
                          <DownloadIcon className="h-6 w-6 mr-2" />
                          {t("Download")}
                        </NeoBrutalistButton>
                        <NeoBrutalistButton
                          onClick={() => handleSend(exercise)}
                          style={
                            exercise.sentToStudents ? "secondary" : "primary"
                          }
                          size="small"
                          className="flex-1"
                        >
                          <PaperAirplaneIcon className="h-6 w-6 mr-2" />
                          {t("Send")}
                        </NeoBrutalistButton>
                        {exercise.sentToStudents && (
                          <NeoBrutalistButton
                            onClick={() => handleCorrection(exercise)}
                            style="primary"
                            size="small"
                            className="flex-1 relative"
                          >
                            <ClipboardCheckIcon className="h-4 w-4 mr-2" />
                            {t("Corrections")}
                            {pendingCorrections[exercise._id] && (
                              <span className="absolute -top-2 -right-3 bg-blue-500 border-2 border-black rounded-full pt-1 shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] hover:shadow-[1px_1px_0px_0px_rgba(0,0,0,1)] transition-all duration-200 cursor-pointer z-1 w-8 h-8 text-sm">{pendingCorrections[exercise._id]}</span>
                               )}
                          </NeoBrutalistButton>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </DraggableExercise>
            ))}
          </div>
          {viewMode === "list" && selectedExercises.length > 0 && (
            <div className="flex justify-end mt-4">
              <NeoBrutalistButton
                onClick={confirmRemoveAllSelected}
                style="error"
                size="small"
              >
                <FaTrash className="w-4 h-4 mr-2" />
                {t("Remove All Selected")}
              </NeoBrutalistButton>
            </div>
          )}
          {isDeleteConfirmationOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <div className="bg-white p-6 rounded-lg border-4 border-black shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
                <h2 className="text-2xl font-bold mb-4">
                  {t("Confirm Deletion")}
                </h2>
                <p className="mb-4">
                  {t("Are you sure you want to delete all selected exercises?")}
                </p>
                <div className="flex justify-end space-x-4">
                  <NeoBrutalistButton onClick={cancelDelete} style="secondary">
                    {t("Cancel")}
                  </NeoBrutalistButton>
                  <NeoBrutalistButton
                    onClick={handleRemoveAllSelected}
                    style="error"
                  >
                    {t("Delete")}
                  </NeoBrutalistButton>
                </div>
              </div>
            </div>
          )}
        </div>

        {deleteConfirmation && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg border-4 border-black shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
              <h2 className="text-2xl font-bold mb-4">
                {t("Confirm Deletion")}
              </h2>
              <p className="mb-4">
                {t("Are you sure you want to delete this exercise?")}
              </p>
              <div className="flex justify-end space-x-4">
                <NeoBrutalistButton onClick={cancelDelete} style="secondary">
                  {t("Cancel")}
                </NeoBrutalistButton>
                <NeoBrutalistButton
                  onClick={confirmDelete}
                  style="error"
                  ref={deleteButtonRef} // Attach ref to the delete button
                >
                  {t("Delete")}
                </NeoBrutalistButton>
              </div>
            </div>
          </div>
        )}
        <NeoBrutalistModal
          isOpen={isAddFolderModalOpen}
          onClose={() => setIsAddFolderModalOpen(false)}
          title={t("Add New Folder")}
        >
          <NeoBrutalistInput
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            placeholder={t("Folder Name")}
          />

          <div className="flex justify-end mt-4">
            <NeoBrutalistButton onClick={handleAddFolder}>
              <CheckIcon className="w-4 h-4 mr-2" />
              {t("Create Folder")}
            </NeoBrutalistButton>
          </div>
        </NeoBrutalistModal>

        {isEditing && editingExercise && (
          <ExerciseEditor
            exercise={editingExercise}
            onSave={handleSaveEdit}
            onClose={handleCloseEdit}
          />
        )}
        <SendExerciseModal
          isOpen={sendModalOpen}
          onClose={handleCloseSendModal}
          exercise={selectedExercise}
          onExerciseSent={handleExerciseSent}
        />
        <ExerciseCorrection
          isOpen={isCorrecting}
          onClose={() => setIsCorrecting(false)}
          exercise={correctingExercise}
          onAllCorrected={handleAllCorrected}
          onAutoCorrected={handleAutoCorrected}
        />
        {showNotification && (
          <NeoBrutalistNotification
            message={notificationMessage}
            onClose={() => setShowNotification(false)}
          />
        )}
      </div>
      <Footer />
      <div
        className={`fixed inset-0 bg-black bg-opacity-50  z-20 ${
          isPreviewModalOpen ? "block" : "hidden"
        }`}
        // onClick={() => setIsPreviewModalOpen(false)}
      >
        <div className="bg-white p-6 rounded-lg">
          {previewExercise && (
            <StudentExercisePage
              loadedExercise={previewExercise}
              isPreview={true}
              onClosePreview={() => setIsPreviewModalOpen(false)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MyExercisesPage;
