import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import axios from 'axios';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
  });

// Fonction pour charger la langue de l'utilisateur
export const loadUserLanguage = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/settings`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    });
    const userLanguage = response.data.language;
    if (userLanguage) {
      i18n.changeLanguage(userLanguage);
    }
  } catch (error) {
    console.error("Erreur lors du chargement de la langue de l'utilisateur:", error);
  }
};

export default i18n;