import axios from 'axios';
import logger from './utils/logger';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const setupAxiosInterceptors = (navigate) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        logger.log(error);
      if (error.response && error.response.data && error.response.data.error === 'Invalid token signature') {
        localStorage.removeItem('token');
        navigate('/login');
      }
      return Promise.reject(error);
    }
  );
};

export { axiosInstance, setupAxiosInterceptors };